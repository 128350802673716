import React, {useEffect, useRef, useState} from "react";
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import {Typography} from "@mui/material";
import {appSettings} from "../../managers/generalManager";
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
// import {AppDispatch, AppState} from "../../store/old/store";
// import {setMainMenuLocked} from "../../store/old/data-slice";
import {useDispatch, useSelector} from "react-redux";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {AppDispatch, RootState} from "../../store/store";
import {setMainMenuLocked} from "../../store/dataSlice";

interface AppDrawerProps {
    children?: React.ReactNode;
    title: string;
    initialState: boolean;
    showLock?: boolean;
}

export default function AppDrawer({children, title, initialState, showLock = true}: AppDrawerProps) {
    const [isExpanded, setIsExpanded] = useState(initialState);
    const [renderContent, setRenderContent] = useState(initialState);
    // const [isLocked, setIsLocked] = useState(false);
    const isFirstRender = useRef(true);
    const expandTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const collapseTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const dispatch = useDispatch<AppDispatch>();
    const mainMenuLocked = useSelector((state: RootState) => state.data.mainMenuLocked);

    useEffect(() => {
        dispatch(setMainMenuLocked(true));
    }, [])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            if (isExpanded) {
                setRenderContent(true);
            }
            return;
        }

        if (isExpanded) {
            expandTimeoutRef.current = setTimeout(() => {
                setRenderContent(true);
            }, 500);
        } else {
            setRenderContent(false);
        }

        return () => {
            if (expandTimeoutRef.current) {
                clearTimeout(expandTimeoutRef.current);
            }
        };
    }, [isExpanded]);

    const handleMouseEnter = () => {
        if (!mainMenuLocked) {
            if (collapseTimeoutRef.current) {
                clearTimeout(collapseTimeoutRef.current);
            }
            setIsExpanded(true);
        }
    };

    const handleMouseLeave = () => {
        if (!mainMenuLocked) {
            collapseTimeoutRef.current = setTimeout(() => {
                setIsExpanded(false);
            }, 300);
        }
    };

    const toggleLock = () => {
        dispatch(setMainMenuLocked(true));
        // dispatch(setMainMenuLocked(!mainMenuLocked));
    };

    return (
        <div
            style={{
                display: 'flex',
                position: 'relative',
                width: isExpanded ? appSettings.maxWidth - 100 : '15px',
                transition: 'width 0.5s',
                borderRight: '2px solid',
                backgroundColor: colorSchema.background.default,
                borderColor: colorSchema.grey[300],
                marginRight: '20px',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <SwitchIcon isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}/>
            {renderContent && isExpanded && (
                <>
                    {children}
                    {/*{showLock && (*/}
                    {/*    <IconButton*/}
                    {/*        onClick={toggleLock}*/}
                    {/*        sx={{*/}
                    {/*            position: 'absolute',*/}
                    {/*            top: '10px',*/}
                    {/*            right: '10px',*/}
                    {/*            color: colorSchema.primary,*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        {mainMenuLocked ? <LockOutlinedIcon/> : <LockOpenOutlinedIcon/>}*/}
                    {/*    </IconButton>*/}
                    {/*)}*/}
                </>
            )}

            {!isExpanded && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%) rotate(-90deg)',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <Typography variant="h6" color={colorSchema.primary}>
                        {title}
                    </Typography>
                </div>
            )}
        </div>
    );
}


type SwitchIconProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    isExpanded: boolean;
};

const SwitchIcon: React.FC<SwitchIconProps> = ({onClick, isExpanded}) => {
    return (
        <IconButton
            onClick={onClick}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '20px',
                height: '48px',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: '0 1rem 1rem 0',
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateX(100%) translateY(-50%)',
                zIndex: 10,
                transition: 'background-color 0.3s ease-out',
                '&:hover': {
                    backgroundColor: 'gray.400',
                }
            }}
        >
            <SvgIcon
                sx={{
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                }}
                fontSize="inherit"
            >
                <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none">
                    <path
                        d="M3.8065 1.473C4.04015 1.23936 4.4078 1.22138 4.66206 1.41909L4.72312 1.473L9.90831 6.65819C10.142 6.89184 10.1599 7.25949 9.96223 7.51375L9.90831 7.57481L4.72312 12.76C4.47001 13.0131 4.05962 13.0131 3.8065 12.76C3.57286 12.5263 3.55488 12.1587 3.75259 11.9044L3.8065 11.8434L8.53338 7.1165L3.8065 2.38962C3.55339 2.13651 3.55339 1.72612 3.8065 1.473Z"
                        fill="currentColor"
                    ></path>
                </svg>
            </SvgIcon>
        </IconButton>
    );
};