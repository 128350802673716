import {CbItem, CbProject, getMenus, getMenuWithTopicsAndServices} from "../backend/lexicalia_api";
import {default_promptDefinitionList, PromptDefinition} from "./promptManager";
import {default_personaDefinitionList, loadPersonaSettings, PersonaDefinition} from "./personaManager";
import logger from "../utils/logging_services";
import colorSchema from "../app_theme/theme_support/colorSchema";
import {SelectedFile} from "../store/types";
import {IndexedDBRecord} from "../utils/IndexedDB.utils";

interface State {
    availableMenus: CbItem[];
    selectedMenu: CbItem | undefined;
}

interface RAGData {
    request: string ;
    domain: string;
    filter_type: string;
    filter_value: string;
    chunks: Chunk[];
}

export type Chunk = {
    reference: string;
    data: string;
};

// CopiedTemplate to make it easy to copy items between stores
export let copiedTemplate: IndexedDBRecord | undefined = undefined;

export function setCopiedTemplate(template: IndexedDBRecord | undefined) {
    copiedTemplate = template;
}


export class AppState implements State {
    availableMenus: CbItem[];
    selectedMenu: CbItem | undefined;

    constructor() {
        this.availableMenus = [];
        this.selectedMenu = undefined;
    }

    getAvailableMenus() {
        return this.availableMenus;
    }

    getSelectedMenu() {
        return this.selectedMenu;
    }

    setAvailableMenus(menus: CbItem[]) {
        this.availableMenus = menus;
    }

    setSelectedMenu(menu: CbItem) {
        this.selectedMenu = menu;
    }

    sortChildList() {
        if (this.selectedMenu !== undefined) {
            sortChildList(this.selectedMenu);
        }
    }
}

// Usage
export const appState = new AppState();

export let selectedMenuLoaded: boolean = false;
// export let organisationName: string = "";
// export let organisationUuid: string = "";
// export let currentUser: string = "";
// export let currentProject: string = "";
// export let currentHrProject: string = "";
// export let currentKnowledgeBaseProject: string = "";
export let debugMode: boolean = true;
//export let accessToken = ""
export let selectedMenuId = "";

// Application parameters
export let selectedLanguage: string = 'en';
export let selectedCategory: string = 'chat';
export let selectedPersona: string = 'None';
export let showWatermark: boolean = true;
export let showAssistantParameter: boolean = true;
export let showLinkedInParameter: boolean = false;
export let showEditor: boolean = true;

export class AppSession {
    accessToken: string = "";
    organisationName: string = "";
    organisationUuid: string = "";
    currentUser: string = "";
    currentProject: string = "";
    currentHrProject: string = "";
    currentKnowledgeBaseProject: string = "";
}

export class AppSettings {
    selectedLanguage: string;
    selectedCategory: string;
    selectedPersona: string;
    selectedLLMModelVersion: string;
    selectedLLMModelProvider: string;
    showWatermark: boolean;
    showAssistantParameter: boolean;
    showRAGData: boolean;
    showEditor: boolean;
    backgroundColor: string;
    dataStore: string;
    database: string;
    storeCategoryList: string;
    temperature: number;
    history_counter: number;
    requestedChunks: number;
    isStreaming: boolean;
    quitStreaming: boolean;
    maxWidth: number;
    dialogHeight: number;
    selectedFile: SelectedFile | undefined;

    constructor() {
        this.selectedLanguage = 'en';
        this.selectedCategory = 'chat';
        this.selectedPersona = 'None';
        this.selectedLLMModelVersion = 'standard';
        this.selectedLLMModelProvider = 'openai';
        this.showWatermark = true;
        this.backgroundColor = colorSchema.background.default;
        this.showAssistantParameter = true;
        this.showRAGData = false;
        this.showEditor = true;
        this.dataStore = "None";
        this.database = 'ZAIA';
        this.temperature = 0.3;
        this.history_counter = 1;
        this.requestedChunks = 5;
        this.isStreaming = false;
        this.quitStreaming = false;
        this.storeCategoryList = "Request, Context-Info, Knowledge, Response-Layout, Request-Plan, Style-Guide, Tone-of-Voice,Other";
        this.maxWidth = 350;
        this.dialogHeight = 800;
        this.selectedFile = undefined;
    }
}

export let ragData: RAGData = {request: "", chunks: [], domain: "", filter_type: "", filter_value: ""};
export let appSettings = new AppSettings();
export let appSession = new AppSession();

export let promptDefinitionList: PromptDefinition[] = default_promptDefinitionList;
export let personaDefinitionList: PersonaDefinition[] = default_personaDefinitionList;

interface LanguageTranslationTable {
    [key: string]: string;
}

// -------------------------------------------------------------------------------------------------------------------
export function saveSession() {
    const session = [
        {key: 'AccessToken', value: appSession.accessToken},
        {key: 'OrganisationName', value: appSession.organisationName},
        {key: 'OrganisationUuid', value: appSession.organisationUuid},
        {key: 'CurrentUser', value: appSession.currentUser},
        {key: 'CurrentProject', value: appSession.currentProject},
        {key: 'CurrentHrProject', value: appSession.currentHrProject},
        {key: 'CurrentKnowledgeBaseProject', value: appSession.currentKnowledgeBaseProject},
    ]
    localStorage.setItem('zaia_session', JSON.stringify(session));
    console.log("Vincent-Session saved", appSession);
}

export function loadSession() {

        const session = localStorage.getItem('zaia_session');
        if (session) {
            const parsedSession = JSON.parse(session);
            parsedSession.forEach((setting: { key: string; value: string }) => {
                switch (setting.key) {
                    case 'AccessToken':
                        appSession.accessToken = setting.value;
                        break;
                    case 'OrganisationName':
                        appSession.organisationName = setting.value;
                        break;
                    case 'OrganisationUuid':
                        appSession.organisationUuid = setting.value;
                        break;
                    case 'CurrentUser':
                        appSession.currentUser = setting.value;
                        break;
                    case 'CurrentProject':
                        appSession.currentProject = setting.value;
                        break;
                    case 'CurrentHrProject':
                        appSession.currentHrProject = setting.value;
                        break;
                    case 'CurrentKnowledgeBaseProject':
                        appSession.currentKnowledgeBaseProject = setting.value;
                        break;
                }
            })
        }

        logger.info("Session loaded", appSession);
}

export const removeSession = () => {
    localStorage.removeItem('zaia_session');

    // localStorage.removeItem('BoostrFoundation_AccessToken');
    // localStorage.removeItem("BoostrFoundation_SelectedOrganisation");
    // localStorage.removeItem("BoostrFoundation_SelectedApplication");
    // localStorage.removeItem("BoostrFoundation_SelectedProject");
    // localStorage.removeItem("BoostrFoundation_SelectedHrProject");
    // localStorage.removeItem('BoostrFoundation_AvailableMenus');
    // localStorage.removeItem('BoostrFoundation_SelectedMenu');
    // localStorage.removeItem('BoostrFoundation_SelectedMenuItems')
}

// -------------------------------------------------------------------------------------------------------------------
export function saveSettings() {
    const settings = [
        {key: 'SelectedLanguage', value: selectedLanguage},
        {key: 'SelectedLLMModelVersion', value: appSettings.selectedLLMModelVersion},
        {key: 'SelectedLLMModelProvider', value: appSettings.selectedLLMModelProvider},
        {key: 'Category', value: selectedCategory},
        {key: 'ShowWatermark', value: showWatermark},
        {key: 'ShowEditor', value: showEditor},
        {key: 'ShowAssistant', value: showAssistantParameter},
        {key: 'SelectedPersona', value: selectedPersona},
        {key: 'Temperature', value: appSettings.temperature},
        {key: 'History_counter', value: appSettings.history_counter},
        {key: 'BackgroundColor', value: appSettings.backgroundColor},
        {key: 'ShowRAGData', value: appSettings.showRAGData},
    ]
    localStorage.setItem('zaia_settings', JSON.stringify(settings));
}

export function loadSettings() {

    const settings = localStorage.getItem('zaia_settings');
    if (settings) {
        const parsedSettings = JSON.parse(settings);
        parsedSettings.forEach((setting: { key: string; value: string | boolean; }) => {
            switch (setting.key) {
                case 'SelectedLanguage':
                    selectedLanguage = setting.value as string;
                    break;
                case 'SelectedLLMModelVersion':
                    appSettings.selectedLLMModelVersion = setting.value as string;
                    break;
                case 'Category':
                    selectedCategory = setting.value as string;
                    break;
                case 'SelectedLLMModelProvider':
                    appSettings.selectedLLMModelProvider = setting.value as string;
                    break;
                case 'ShowEditor':
                    showEditor = setting.value as boolean;
                    break;
                case 'ShowWatermark':
                    showWatermark = setting.value as boolean;
                    break;
                case 'ShowAssistant':
                    showAssistantParameter = setting.value as boolean;
                    break;
                case 'SelectedPersona':
                    selectedPersona = setting.value as string;
                    break;
                case 'BackgroundColor':
                    appSettings.backgroundColor = setting.value as string;
                    break;
                case 'Temperature':
                    appSettings.temperature = setting.value as unknown as number;
                    break;
                case 'History_counter':
                    appSettings.history_counter = setting.value as unknown as number;
                    break;
                case 'ShowRAGData':
                    appSettings.showRAGData = setting.value as boolean;
                    break;
            }
        })

        logger.info("Settings loaded", appSettings);
        loadPersonaSettings();
    }

    logger.info("PromptDefinitionList loaded");
    const local_promptDefinitionList = localStorage.getItem('BoostrFoundation_PromptDefinitionList');
    if (local_promptDefinitionList) {
        try {
            promptDefinitionList = JSON.parse(local_promptDefinitionList);

            // add items in default_promptDefinitionList that are not in promptDefinitionList
            default_promptDefinitionList.forEach((defaultItem) => {
                const foundItem = promptDefinitionList.find((item) => item.name === defaultItem.name);
                if (!foundItem) {
                    promptDefinitionList.push(defaultItem);
                }
            })

        } catch (error) {
            if ( error instanceof Error )
                logger.error("Error parsing promptDefinitionList", error);
            promptDefinitionList = default_promptDefinitionList;
        }
    }
}


const languageTranslationTable: LanguageTranslationTable = {
    'EN': 'en-US',
    'GB': 'en-GB',
    'FR': 'fr-FR',
    'DE': 'de-DE',
    'IT': 'it-IT',
    'ES': 'es-ES',
    'MX': 'es-MX',
    'CN': 'zh-CN',
    'JP': 'ja-JP',
    'KR': 'ko-KR',
    'NL': 'nl-NL',
    'AR': 'ar-AR',
};

export const snippetTypes: string[] =
    [
        "html",
        "csv",
        "mermaid",
    ];

export function getLanguageCode(shortCode: string) {
    return languageTranslationTable[shortCode.toUpperCase()] || 'en-US'; // Default to 'en-US' if not found
}

export function setSelectedPersona(persona: string) {
    selectedPersona = persona;
}


export function setSelectedMenuId(menuId: string) {
    selectedMenuId = menuId;
}

export function setSelectedCategory(category: string) {
    selectedCategory = category;
}


export function setPersonaDefinitionList(personaList: PersonaDefinition[]) {
    personaDefinitionList = personaList;
}

// FIND ITEM IN LIST OF ITEMS BASED ON ID
export const findItem = (items: CbItem[], uuid: string): CbItem | null => {
    for (const item of items) {
        if (item.uuid === uuid) {
            return item;
        }

        const foundItem = findItem(item.child_list, uuid);
        if (foundItem !== null) {
            return foundItem;
        }
    }
    return null;
}


// FIND ITEM IN LIST OF ITEMS BASED ON TITLE
export const findItemOnName = (items: CbItem[], title: string): CbItem | null => {
    for (const item of items) {
        if (item.title_text === title) {
            return item;
        }

        const foundItem = findItemOnName(item.child_list, title);
        if (foundItem !== null) {
            return foundItem;
        }
    }
    return null;
}

export const sortChildList = (item: CbItem) => {
    item.child_list.sort((a, b) => a.sequence - b.sequence);
    item.child_list.forEach((childItem) => sortChildList(childItem));
}


export async function getAvailableMenus(): Promise<{ data: CbItem[] | null; error: any | null }> {
    return new Promise(async (resolve) => {

        // Retrieve menu(s) and store them in availableMenus
        let menuResponse = await getMenus(appSession.currentProject);
        let project: CbProject;

        // Check if user has no assigned menus, then switch to 'free' mode
        if (menuResponse.data && (menuResponse.data.length === 0)) {
            logger.error(`getMenus : No menus for user`);
            resolve({data: null, error: "No menus found for user"});
            return;
        } else {
            if (menuResponse.data !== null) {
                appState.setAvailableMenus(menuResponse.data);
            } else {
                resolve({data: null, error: "No menus found for user"});
                return;
            }
            logger.info("getAvailableMenus - availableMenus:", appState.availableMenus);
        }

        if (menuResponse.error) {
            resolve({data: null, error: menuResponse.error});
            return;
        }

        logger.info("ZAIA Menu's Loaded");
        resolve({data: appState.availableMenus, error: null});
    })
}


export async function getMenuItems(projectUuid: string, menuUuid: string): Promise<{
    data: CbItem | null;
    error: any | null
}> {
    return new Promise(async (resolve) => {

        const selectedMenuResponse = await getMenuWithTopicsAndServices(projectUuid, menuUuid);

        if (selectedMenuResponse.error) {
            resolve({data: null, error: selectedMenuResponse.error});
            return;
        }

        if (selectedMenuResponse.data) {

            // extra services are used by the service icons in editor mode.
            let extraServices = await import('../app_theme/default_services.json');

            logger.info("selectedMenu:", selectedMenuResponse.data);
            selectedMenuResponse.data.child_list.push(extraServices);

            appState.setSelectedMenu(selectedMenuResponse.data);
            appState.sortChildList();
            selectedMenuLoaded = true;

            logger.info("ZAIA Default Menu Loaded");

            // @ts-ignore
            resolve({data: appState.selectedMenu, error: null});
        } else {
            resolve({data: null, error: "No menus found for user"});
        }

    })
}


export function initData(language: string) {


}

export interface multiLangContent {
    nl?: string;
    en?: string;
    fr?: string;

    [key: string]: string | undefined;
}

export function t(data: multiLangContent | null | undefined, language: string): string {
    if (!data) {
        return "";
    }

    let response: string | undefined = data[language];  // Nu toegestaan vanwege index signature

    if (!response) {
        response = data.nl || "";  // Nederlands als fallback, lege string als laatste redmiddel
    }

    return response;
}

export function setOrganisationNameAndId(name: string, uuid: string) {
    appSession.organisationName = name;
    appSession.organisationUuid = uuid;
}

export function setShowWatermark(state: boolean) {
    showWatermark = state;
}

export function setShowAssistantParameter(state: boolean) {
    showAssistantParameter = state;
}

export function setShowEditor(state: boolean) {
    showEditor = state;
}

export function setShowLinkedInParameter(state: boolean) {
    showLinkedInParameter = state;
}

export function setBackgroundColor(color: string) {
    appSettings.backgroundColor = color;
}

export function setApplicationLanguage(language: string) {
    selectedLanguage = language;
    logger.info('Application language :', language);
}

export function setCurrentProject(projectUuid: string) {
    appSession.currentProject = projectUuid;
}

export function setCurrentHrProject(projectUuid: string) {
    appSession.currentHrProject = projectUuid;
}

export function setCurrentKnowledgeBaseProject(projectUuid: string) {
    appSession.currentKnowledgeBaseProject = projectUuid;
}

export function setQuitStreaming(quit: boolean) {
    appSettings.quitStreaming = quit;
}

export function setIsStreaming(state: boolean) {
    logger.info("setIsStreaming", state);
    appSettings.isStreaming = state;
}

export function setCurrentUser(name: string) {
    appSession.currentUser = name;
}

export function updateAccessToken(token: string) {
    appSession.accessToken = token;
}

export function updateSelectedLanguage(language: string) {
    selectedLanguage = language;
    appSettings.selectedLanguage = language;
}

export function updateShowRAGData(state: boolean) {
    appSettings.showRAGData = state;
}

export function setLLMModelVersion(llmVersion: string) {
    logger.info("setLLMModelVersion", llmVersion)
    appSettings.selectedLLMModelVersion = llmVersion;
}

export function setLLMModelProvider(llmProvider: string) {
    logger.info("setLLMModelProvider", llmProvider)
    appSettings.selectedLLMModelProvider = llmProvider;
}


export interface Option {
    value: string;
    label: string;
    image?: string;
    tag?: string;
}

export function extractName(username: string) {
    // Remove domain if present
    let namePart = username.split('@')[0];

    // Split into forename and family name
    let names = namePart.split('.');
    let firstName = names[0];
    let lastName = names.length > 1 ? names[1] : "";

    // Create initials
    let initials = firstName[0] + (lastName ? lastName[0] : '');

    return {firstName, lastName, initials};
}

export const languageOptions: Option[] = [
    {value: "nl", label: "Dutch"},
    {value: "en", label: "English"},
    {value: "fr", label: "French"},
    {value: "de", label: "Deutsch"},
    {value: "it", label: "Italian"},
    {value: "es", label: "Spanish"},
    {value: "zh", label: "Chinese"},
    {value: "tl", label: "Tagalog"},
    {value: "ar", label: "Arabic"},
];

export const llmModelVersion: Option[] = [
    {value: "standard", label: "Standard"},
    {value: "empowered", label: "Empowered"},
];

export const llmModelProvider: Option[] = [
    {value: "openai", label: "OpenAI"},
    {value: "azure", label: "Microsoft"},
    {value: "anyscale", label: "Mistral"},
    {value: "google", label: "Google"},
    {value: "anthropic", label: "Anthropic"},
    {value: "meta", label: "Meta"},
    {value: "nvidia", label: "Nvidia"},
    {value: "custom", label: "Experimental"},
];

export const trueFalse: Option[] = [
    {value: "true", label: "On"},
    {value: "false", label: "Off"},
];

export const categoryOptions: Option[] = [
    {value: "chat", label: "None"},
    {value: "HR", label: "Human Resource"},
    {value: "Service Delivery", label: "Service Delivery"},
    {value: "Helpdesk", label: "Helpdesk"},
    {value: "Training", label: "Training"},
    {value: "Legal", label: "Legal"},
    {value: "Corporate", label: "Corporate"},
    {value: "Blog", label: "Blog"},
    {value: "Demo", label: "Demo"},
    {value: "Sales", label: "Sales"},
    {value: "Facts", label: "Facts"},
];

export enum DefaultCategories {
    GOALS = "Goals",
    TEMPLATES = "Templates",
    PROMPTS = "Prompts",
    PERSONAS = "Persona's",
    DATA = "Data",
}



