import React, {useEffect, useMemo} from "react";
import AppDialog from "../general/app_dialog";
import {
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {ColorResult} from "react-color";
import {EditorViewer} from "../viewers/editor_viewer";
import {ContentState, convertToRaw, EditorState, Modifier} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {getAllFromIndexedDB, getObjectStoresInDatabase, IndexedDBRecord} from "../../utils/IndexedDB.utils";
import DropdownSelector from "../chat/dropdownSelector";
import {useSelector} from "react-redux";
import {SelectFolder} from "../files/components/select_folder";
import {SelectFileInFolder} from "../files/components/select_file_in_folder";
import {ObjectItem} from "../files/file_navigator";
import {CSVHeaderFieldsForm, CVSHeaderFields} from "./store_csv_header";
import {GoalFormAssistant, GoalDefinitionOptions, GoalDefinitionSettingsOptions} from "./store_data_details_helpers";
import ColorPickButton, {hexToRgbA, rgbAToHex} from "../general/color_button";
import ComponentSelectZaiaServices from "./store_data_details_services";
import logger from "../../utils/logging_services";
import {removeHTMLTags} from "../../utils/common_tools";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {
    appSettings,
    copiedTemplate,
    llmModelProvider,
    llmModelVersion,
    Option,
    setCopiedTemplate
} from "../../managers/generalManager";
import {RootState} from "../../store/store";
import {Filter} from "../filter/filter_component";

interface Color {
    r: number;
    g: number;
    b: number;
    a: number;
}

interface StoreDataDetailProps {
    activeRecord: IndexedDBRecord;
    availableTemplates: string[];
    availablePrompts: Option[];
    dialogMode: string;
    uniqueCategories: string[];
    defaultCategory: string;
    onCancel: () => void;
    onSubmitted: (record: IndexedDBRecord) => void;
}

export const StoreDataDetail = ({
                                    activeRecord,
                                    availableTemplates,
                                    availablePrompts,
                                    dialogMode,
                                    uniqueCategories,
                                    defaultCategory,
                                    onCancel,
                                    onSubmitted
                                }: StoreDataDetailProps) => {
    const [selectedRecord, setSelectedRecord] = React.useState<IndexedDBRecord>(activeRecord);

    const [category, setCategory] = React.useState<string>(defaultCategory);
    const [name, setName] = React.useState<string>("");
    const [tag, setTag] = React.useState<string>("");
    const [sequence, setSequence] = React.useState<number>(0);
    const [description, setDescription] = React.useState<string>("");
    const [categoryType, setCategoryType] = React.useState<string>("prompt");
    const [editorStateValue, setEditorStateValue] = React.useState<EditorState>(EditorState.createEmpty());
    const [editorStateTemplate, setEditorStateForm] = React.useState<EditorState>(EditorState.createEmpty());
    const [value, setValue] = React.useState<string>("");
    const [form, setForm] = React.useState<string>("");
    const [selectedTemplate, setSelectedTemplate] = React.useState<string>("");
    const [backgroundColor, setBackgroundColor] = React.useState<Color>({
        r: 241,
        g: 112,
        b: 19,
        a: 1,
    });
    const [selectedWorkspaceShared, setSelectedWorkspaceShared] = React.useState<string>("None");
    const [selectedWorkspacePersonal, setSelectedWorkspacePersonal] = React.useState<string>("None");
    const [selectedContentType, setSelectedContentType] = React.useState<string>("None");
    const [selectedLLMVersion, setSelectedLLMVersion] = React.useState<string>("None");
    const [selectedOutputFormat, setSelectedOutputFormat] = React.useState<string>("html");
    const [selectedLLMProvider, setSelectedLLMProvider] = React.useState<string>("None");
    const [enableGoal, setEnableGoal] = React.useState<string>("Yes");
    const [selectedPrompt, setSelectedPrompt] = React.useState<string>("None");
    const [removePreselectedWorkspaceItems, setRemovePreselectedWorkspaceItems] = React.useState<boolean>(false);
    const [removePreselectedWorkspaceItemsOnClose, setRemovePreselectedWorkspaceItemsOnClose] = React.useState<boolean>(false);
    const [fileCategory, setFileCategory] = React.useState<string>("chat");
    const [CSVFile, setCSVFile] = React.useState<ObjectItem | undefined>(undefined);
    const [settingsMode, setSettingsMode] = React.useState<string>("general");

    const [updatedCSVHeaderFields, setUpdatedCSVHeaderFields] = React.useState<{ [key: string]: string }>({});
    const [initialCSVHeaderFields, setInitialCSVHeaderFields] = React.useState<{ [key: string]: string }>({});

    const [selectedWorkflowStorePrev, setSelectedWorkflowStorePrev] = React.useState<string>("None");
    const [selectedWorkflowStoreNext, setSelectedWorkflowStoreNext] = React.useState<string>("None");
    const [availableGoalsPrev, setAvailableGoalsPrev] = React.useState<Option[]>([{value: 'None', label: 'None'}]);
    const [availableGoalsNext, setAvailableGoalsNext] = React.useState<Option[]>([{value: 'None', label: 'None'}]);
    const [selectedGoalPrev, setSelectedGoalPrev] = React.useState<string>("");
    const [selectedGoalNext, setSelectedGoalNext] = React.useState<string>("");

    const [availableStores, setAvailableStores] = React.useState<Option[]>([]);

    const [selectedKnowledgeDomain, setSelectedKnowledgeDomain] = React.useState<string>("None");
    const [selectedKnowledgeDomainFilter, setSelectedKnowledgeDomainFilter] = React.useState<string>("None");

    const workspaces = useSelector((state: RootState) => state.workspaces.workspaces);

    // state variable, triggerd by the selected CSVFile or default values
    const CSVHeaderFields: { [key: string]: string } = CVSHeaderFields({
        CSVFile,
    });

    const yesnoOptions = [{value: "Yes", label: "Yes"}, {value: "No", label: "No"}];


    useEffect(() => {

        const fetchStores = async () => {
            try {
                const stores = await getObjectStoresInDatabase(appSettings.database);
                const formattedStores = stores.map((store: string) => ({
                    label: store,
                    value: store
                }));
                formattedStores.unshift({label: 'None', value: 'None'});

                setAvailableStores(formattedStores);
            } catch (error: any) {
                logger.error('Error fetching stores:', error);
            }
        };

        if ( copiedTemplate !== undefined ) {
            setSelectedRecord(copiedTemplate);
            setCopiedTemplate(undefined);
        }
        fetchStores();

    }, []);

    // When the CSV file definition is loaded, update default values based on the saved values in the goal.
    useEffect(() => {

        console.log("CSVHeaderFields updated:", CSVHeaderFields);
        if (CSVHeaderFields === undefined)
            return;

        // merge field values of initialField to headerFields
        const newHeaderFields = {...CSVHeaderFields};
        Object.keys(initialCSVHeaderFields).forEach((field) => {
            newHeaderFields[field] = initialCSVHeaderFields[field];
        });
        setInitialCSVHeaderFields(newHeaderFields);
        setUpdatedCSVHeaderFields(newHeaderFields);

    }, [CSVHeaderFields])

    // Load selected DBItem record
    React.useEffect(() => {
        if (selectedRecord ) {

            if (selectedRecord.value.category === "" || selectedRecord.value.category === undefined) {
                setCategory(defaultCategory);
            } else {
                setCategory(selectedRecord.value.category);
            }

            setSelectedPrompt(selectedRecord.value.systemPrompt || "None")
            setSelectedOutputFormat(selectedRecord.value.outputFormat || "html")

            setCategory(selectedRecord.value.category || defaultCategory);
            setName(selectedRecord.value.name || "");
            setEnableGoal(selectedRecord.value.enable || "Yes");
            setTag(selectedRecord.value.tag || "");
            setSequence(selectedRecord.value.sequence || 0);
            setDescription(selectedRecord.value.description || "");
            setValue(selectedRecord.value.value || "");
            setForm(selectedRecord.value.form || "");
            setSelectedLLMProvider(selectedRecord.value.llmModelProvider || "None");
            setSelectedLLMVersion(selectedRecord.value.llmModelVersion || "None");
            setSelectedWorkspaceShared(selectedRecord.value.workspaceShared || "None");
            setSelectedWorkspacePersonal(selectedRecord.value.workspacePersonal || "None");
            setSelectedContentType(selectedRecord.value.workspaceContentType || "None");
            setSelectedTemplate(selectedRecord.value.template || availableTemplates[0])
            setRemovePreselectedWorkspaceItems(selectedRecord.value.removePreselectedWorkspaceItems || false);
            setRemovePreselectedWorkspaceItemsOnClose(selectedRecord.value.removePreselectedWorkspaceItemsOnClose || false);
            loadEditorState(selectedRecord.value.value, selectedRecord.value.form || "");

            setSelectedKnowledgeDomain(selectedRecord.value.knowledgeDomain || "None");
            setSelectedKnowledgeDomainFilter(selectedRecord.value.knowledgeDomainFilter || "None");

            console.log("Selected Record:", selectedRecord.value)
            setFileCategory(selectedRecord.value.csvCategory || "chat");
            setCSVFile(selectedRecord.value.csvFile || undefined);
            setInitialCSVHeaderFields(selectedRecord.value.csvHeaderFields || {});
            setSelectedOutputFormat(selectedRecord.value.outputFormat || "html");
            setBackgroundColor(selectedRecord.value.backgroundColour ? hexToRgbA(selectedRecord.value.backgroundColour) : hexToRgbA(colorSchema.background.default));

        }
    }, [selectedRecord]);

    useEffect(() => {
        setCategory(selectedRecord.value.category || defaultCategory);
    }, [defaultCategory])

    useEffect(() => {

        if (selectedWorkflowStorePrev === 'None')
            return

        getStoreItems(selectedWorkflowStorePrev, 'goals').then((data) => {
            setAvailableGoalsPrev(data);
        });

    }, [selectedWorkflowStorePrev]);

    useEffect(() => {

        if (selectedWorkflowStoreNext === 'None')
            return;

        getStoreItems(selectedWorkflowStoreNext, 'goals').then((data) => {
            setAvailableGoalsNext(data);
        });

    }, [selectedWorkflowStoreNext]);


    const workSpaceOptionsPersonal = useMemo(() => {
        return [
            {label: 'None', value: 'None'},
            ...workspaces
                .slice()
                .filter((workspace) => workspace.user_scope)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((workspace) => ({label: workspace.name, value: workspace.name}))
        ];
    }, [workspaces]);

    const workSpaceOptionsGlobal = useMemo(() => {
        return [
            {label: 'None', value: 'None'},
            ...workspaces
                .slice()
                .filter((workspace) => !workspace.user_scope)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((workspace) => ({label: workspace.name, value: workspace.name}))
        ];
    }, [workspaces]);

    const availableLLMVersions = useMemo(() => {
        return [
            {label: 'None', value: 'None'},
            ...llmModelVersion
                .map((version: { value: any; }) => ({label: version.value, value: version.value}))
        ];
    }, []);

    const memoizedStores = useMemo(() => availableStores, [availableStores]);


    const availableOutputFormatOption = useMemo(() => {
            return [
                {label: 'HTML', value: 'html'},
                {label: 'JSON', value: 'json'},
                {label: 'CSV', value: 'csv'},
                {label: 'Text', value: 'text'},
            ];
        }
        , []);

    const availableLLMProviders = useMemo(() => {
        return [
            {label: 'None', value: 'None'},
            ...llmModelProvider
                .map((version) => ({label: version.value, value: version.value}))
        ];
    }, []);


    const workspaceContentTypes = useMemo(() => {
        // search for the workspace in the workspaces array

        if (selectedWorkspacePersonal === "None" && selectedWorkspaceShared === "None") {
            return [{label: 'None', value: 'None'}];
        }
        const workspaceName = selectedWorkspaceShared !== 'None' ? selectedWorkspaceShared : selectedWorkspacePersonal;
        const workspace = workspaces.find((workspace) => workspace.name === workspaceName);

        if (workspace) {
            const sortedContentTypes = workspace.folder_list
                .map((folder) => ({label: folder.name, value: folder.name}));

            return [{label: 'None', value: 'None'}, ...sortedContentTypes];
        } else {
            return [{label: 'None', value: 'None'}];
        }
    }, [selectedWorkspaceShared, selectedWorkspacePersonal, workspaces]);


    // Validate input fields and prepare new record state, then call parent function to save
    // ----------------------------------------------------------------------------------------
    const handleDialogRequest = () => {

        // validate input fields
        if (name === "") {
            console.log("Name is required");
            return;
        }

        // prepare new state
        let record = {...selectedRecord};

        record.value.enable = enableGoal;

        record.value.name = name;
        record.value.tag = tag;
        record.value.sequence = sequence;
        record.value.description = description;
        record.value.value = value;
        record.value.form = form;
        record.value.category = category;
        record.value.template = selectedTemplate;
        record.value.outputFormat = selectedOutputFormat;
        record.value.systemPrompt = selectedPrompt;

        record.value.llmModelProvider = selectedLLMProvider;
        record.value.llmModelVersion = selectedLLMVersion;
        record.value.workspaceShared = selectedWorkspaceShared;
        record.value.workspacePersonal = selectedWorkspacePersonal;
        record.value.workspaceContentType = selectedContentType;
        record.value.removePreselectedWorkspaceItems = removePreselectedWorkspaceItems;
        record.value.removePreselectedWorkspaceItemsOnClose = removePreselectedWorkspaceItemsOnClose;

        record.value.knowledgeDomain = selectedKnowledgeDomain;
        record.value.knowledgeDomainFilter = selectedKnowledgeDomainFilter;

        record.value.csvCategory = fileCategory;
        record.value.csvFile = CSVFile;
        record.value.csvHeaderFields = updatedCSVHeaderFields;

        record.value.backgroundColour = rgbAToHex(backgroundColor);

        logger.info("Record Prepared to save:", record)
        onSubmitted(record)

    };

    // Insert instruction into form builder
    const handleInsert = (type: string): void => {
        let textToInsert = "";
        if (type === 'header') {
            textToInsert = "Define header title ({type:header})";
        } else if (type === 'text') {
            textToInsert = "Define text here ({type:body})";
        } else if (type === 'field') {
            textToInsert = "[field_name({rows:1})]";
        } else if (type === 'choice') {
            textToInsert = "Select one of the given values [field_name({choice:value1|value2|value3|value4})]";
        } else if (type === 'example') {
            textToInsert = "Define example ({type:example})\n";
        }

        const currentContent = editorStateTemplate.getCurrentContent();
        const currentBlockMap = currentContent.getBlockMap();
        const lastBlock = currentBlockMap.last();
        const endKey = lastBlock.getKey();
        const endLength = lastBlock.getLength();

        const selection = editorStateTemplate.getSelection().merge({
            anchorKey: endKey,
            anchorOffset: endLength,
            focusKey: endKey,
            focusOffset: endLength,
        });

        const newContentState = Modifier.insertText(
            currentContent,
            selection,
            '\n' + textToInsert
        );

        const newEditorState = EditorState.push(
            editorStateTemplate,
            newContentState,
            'insert-characters'
        );

        setEditorStateForm(newEditorState);
    };

    // Create new editor state based on given context app_theme
    const loadEditorState = (value: string, form: string) => {
        function convertToEditorState(value: string) {
            const {contentBlocks, entityMap} = htmlToDraft(value || "");
            const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            return EditorState.createWithContent(newContentState);
        }

        setEditorStateValue(convertToEditorState(value));
        setEditorStateForm(convertToEditorState(form));
    };

    // Update editor state with user entered context app_theme
    const handleEditorStateChange = (newState: EditorState, type: string) => {
        if (type === 'prompt') {
            setEditorStateValue(newState);
            setValue(draftToHtml(convertToRaw(newState.getCurrentContent())));
        } else {
            setEditorStateForm(newState);
            setForm(draftToHtml(convertToRaw(newState.getCurrentContent())));
        }
    };

    // Settings mode determines to show general, input or output settings.
    const handleSettingsMode = (mode: string) => {
        setSettingsMode(mode);
    }

    const handleFileCategorySelected = (categoryOption: Option) => {
        setFileCategory(categoryOption.value);
    }

    const handleCSVFileSelected = (selectedFile: ObjectItem) => {
        setCSVFile(selectedFile);
    }

    const handleColorChange = (color: ColorResult) => {
        const {r, g, b, a} = color.rgb;
        setBackgroundColor({
            r,
            g,
            b,
            a: a !== undefined ? a : 1, // Provide a default value for a if it's undefined
        });
    };

    const handleEnableGoal = (selected: string) => {
        setEnableGoal(selected);
    }

    const handleZaiaServicesChangeCategory = (category: string) => {
        setSelectedKnowledgeDomain(category);
    }

    const handleZaiaKnowledgeDomainFilter = (filterList: Filter[]) => {
        // converter filter to string
        const filterString = JSON.stringify(filterList);
        setSelectedKnowledgeDomainFilter(filterString);
    }

    // -----------------------------------------------------------------------------------------------------------
    // componentSelectTemplate
    // -----------------------------------------------------------------------------------------------------------
    const componentSelectTemplate = () => {
        return (
            <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                marginTop: '10px',
                alignItems: 'center'
            }}>
                <div style={{flex: '5', flexDirection: 'column'}}>
                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>Template</Typography>
                    <Typography variant="subtitle2">
                        Select a template you wish to use with this service.
                    </Typography>
                </div>

                <div style={{flex: '5'}}>
                    <DropdownSelector
                        options={availableTemplates.map((template) => ({
                            label: template,
                            value: template
                        }))}
                        onChange={(selected) => {
                            setSelectedTemplate(selected);
                        }}
                        defaultValue={selectedTemplate || availableTemplates[0]}
                        label="Template"
                    />
                </div>
            </div>
        )
    }
    // -----------------------------------------------------------------------------------------------------------
    // componentSelectTemplate
    // -----------------------------------------------------------------------------------------------------------
    const componentSelectSystemPrompt = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
                alignItems: 'center'
            }}>
                <div style={{flex: '5', flexDirection: 'column'}}>
                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>System Prompt</Typography>
                    <Typography variant="subtitle2">
                        Select the system prompt you wish to use with this service.
                    </Typography>
                    <Typography variant="subtitle2">
                        "None" will use the default application system prompt.
                    </Typography>
                </div>

                <div style={{flex: '5'}}>
                    <DropdownSelector
                        options={availablePrompts}
                        onChange={(selected) => {
                            setSelectedPrompt(selected);
                        }}
                        defaultValue={selectedPrompt || availablePrompts[0].value}
                        label="System Prompt"
                    />
                </div>
            </div>
        )
    }
    // -----------------------------------------------------------------------------------------------------------
    // componentSelectTemplate
    // -----------------------------------------------------------------------------------------------------------
    const componentSelectColor = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
                alignItems: 'center'
            }}>
                <div style={{flex: '5', flexDirection: 'column'}}>
                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>Colors</Typography>
                    <Typography variant="subtitle2">
                        You can change the default colors.
                    </Typography>
                </div>

                <div style={{flex: '5'}}>
                    <ColorPickButton
                        color={backgroundColor}
                        onChange={handleColorChange}/>
                </div>
            </div>
        )
    }
    // -----------------------------------------------------------------------------------------------------------
    // componentEnableGoal
    // -----------------------------------------------------------------------------------------------------------
    const componentEnableGoal = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
                alignItems: 'center'
            }}>
                <div style={{flex: '5', flexDirection: 'column'}}>
                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>Enable/Disable</Typography>
                    <Typography variant="subtitle2">
                        Allow users to use this Goal?
                    </Typography>
                </div>

                <div style={{flex: '2', display: 'flex', flexDirection: 'column'}}>
                    <DropdownSelector
                        variant={"outlined"}
                        defaultValue={yesnoOptions.find(option => option.value === enableGoal)?.value}
                        options={yesnoOptions}
                        label=""
                        onChange={handleEnableGoal}
                    />
                </div>
            </div>
        )
    }
    // -----------------------------------------------------------------------------------------------------------
    // componentSelectInputWorkspace
    // -----------------------------------------------------------------------------------------------------------
    const componentSelectInputWorkspace = () => {
        return (
            <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <div style={{flex: '5', flexDirection: 'column'}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>Workspace</Typography>
                        <Typography variant="subtitle2">
                            Workspace items can be used to provide input data for the service.
                        </Typography>
                        <Typography variant="subtitle2">
                            Choose a personal or public workspace to assign to this service.
                        </Typography>
                        <Typography variant="subtitle2">
                            You can select one, but not both.
                        </Typography>
                    </div>

                    <div style={{flex: '5'}}>
                        <DropdownSelector
                            options={workSpaceOptionsGlobal}
                            onChange={(selected) => {
                                setSelectedWorkspaceShared(selected);
                                setSelectedWorkspacePersonal("None");
                            }}
                            defaultValue={selectedWorkspaceShared || workSpaceOptionsGlobal[0].value}
                            label="Public Workspace"
                        />
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <div style={{flex: '5', flexDirection: 'column'}}>
                    </div>

                    <div style={{flex: '5'}}>
                        <DropdownSelector
                            options={workSpaceOptionsPersonal}
                            onChange={(selected) => {
                                setSelectedWorkspacePersonal(selected);
                                setSelectedWorkspaceShared("None");
                            }}
                            defaultValue={selectedWorkspacePersonal || workSpaceOptionsPersonal[0].value}
                            label="Personal Workspace"
                        />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '20px',
                    alignItems: 'center'
                }}>
                    <div style={{flex: '5', flexDirection: 'column'}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>Folder</Typography>
                        <Typography variant="subtitle2">
                            Choose the workspace type .
                        </Typography>
                    </div>

                    <div style={{flex: '5'}}>
                        <DropdownSelector
                            options={workspaceContentTypes}
                            onChange={(selected) => {
                                setSelectedContentType(selected);
                            }}
                            defaultValue={selectedContentType || workspaceContentTypes[0].value}
                            label="Workspace Folders"
                        />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '20px',
                    alignItems: 'center'
                }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={removePreselectedWorkspaceItems}
                                onChange={(e) => setRemovePreselectedWorkspaceItems(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Remove all pre-selected workspace items when this goal is selected"
                    />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={removePreselectedWorkspaceItemsOnClose}
                                onChange={(e) => setRemovePreselectedWorkspaceItemsOnClose(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Remove all pre-selected workspace items when this service request is closed"
                    />
                </div>
            </>
        )
    }

    // -----------------------------------------------------------------------------------------------------------
    // componentSelectLLM
    // -----------------------------------------------------------------------------------------------------------
    const componentSelectLLM = () => {
        return (

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <div style={{flex: '5', flexDirection: 'column'}}>
                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>LLM</Typography>
                    <Typography variant="subtitle2">
                        Choose the LLM service to use.
                    </Typography>
                    <Typography variant="subtitle2">
                        This service selection will influence various factors such as :
                    </Typography>
                    <Typography variant="subtitle2">
                        quality, cost, speed, and privacy.
                    </Typography>
                    <Typography variant="subtitle2">
                        If not specified, the application will default to the service defined in the
                        application settings.
                    </Typography>
                </div>
                <div style={{flex: '5', flexDirection: 'column'}}>
                    <DropdownSelector
                        options={availableLLMProviders}
                        onChange={(selected) => {
                            setSelectedLLMProvider(selected);
                        }}
                        defaultValue={selectedLLMProvider || availableLLMProviders[0].value}
                        label="Provider"
                    />
                    <div style={{marginTop: '10px'}}/>
                    <DropdownSelector
                        options={availableLLMVersions}
                        onChange={(selected) => {
                            setSelectedLLMVersion(selected);
                        }}
                        defaultValue={selectedLLMVersion || availableLLMVersions[0].value}
                        label="Version"
                    />

                </div>
            </div>
        )
    }

    // -----------------------------------------------------------------------------------------------------------
    // OUTPUT : componentSelectOutput
    // -----------------------------------------------------------------------------------------------------------
    const componentSelectOutput = () => {
        return (
            <div style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}>
                    <div style={{flex: '1', display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>Output</Typography>
                        <Typography variant="subtitle2">
                            Determine the output of the service.
                        </Typography>
                    </div>
                    <div style={{flex: '2', display: 'flex', flexDirection: 'column'}}>
                        <DropdownSelector
                            options={availableOutputFormatOption}
                            onChange={(selected) => {
                                setSelectedOutputFormat(selected);
                            }}
                            defaultValue={selectedOutputFormat || availableOutputFormatOption[0].value}
                            label="Output Format"
                        />
                        {selectedOutputFormat === 'csv' && (
                            <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
                                <SelectFolder onSelection={handleFileCategorySelected}/>
                                <SelectFileInFolder selectedCategory={fileCategory}
                                                    selectedFilter={".csv"}
                                                    selectedFile={CSVFile?.filename}
                                                    onFileSelected={handleCSVFileSelected}/>
                            </div>
                        )}
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <CSVHeaderFieldsForm CSVHeaderFields={initialCSVHeaderFields}
                                         onUpdateFields={setUpdatedCSVHeaderFields}/>
                </div>

            </div>
        )
    }

    // -----------------------------------------------------------------------------------------------------------
    // WORKFLOW :
    // -----------------------------------------------------------------------------------------------------------
    const componentSelectWorkflow = () => {


        return (
            <div style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}>
                    <div style={{flex: '1', display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>WorkFlow Definition</Typography>
                        <Typography variant="subtitle2">
                            Previous workflow step
                        </Typography>
                    </div>
                    <div style={{flex: '2', display: 'flex', flexDirection: 'column'}}>

                        <div style={{marginBottom: '20px'}}>
                            <DropdownSelector
                                options={memoizedStores}
                                onChange={(selected) => {
                                    setSelectedWorkflowStorePrev(selected);
                                }}
                                defaultValue={selectedWorkflowStorePrev || memoizedStores[0].value}
                                label="Store - Previous Step"
                            />
                        </div>
                        <div style={{marginBottom: '20px'}}>
                            <DropdownSelector
                                options={availableGoalsPrev}
                                onChange={(selected) => {
                                    setSelectedGoalPrev(selected);
                                }}
                                defaultValue={selectedGoalPrev || availableGoalsPrev[0].value}
                                label="Store - Next Step"
                            />
                        </div>
                        <div style={{marginBottom: '20px'}}>
                            <DropdownSelector
                                options={memoizedStores}
                                onChange={(selected) => {
                                    setSelectedWorkflowStoreNext(selected);
                                }}
                                defaultValue={selectedWorkflowStoreNext || memoizedStores[0].value}
                                label="Store - Next Step"
                            />
                        </div>
                        <div style={{marginBottom: '20px'}}>
                            <DropdownSelector
                                options={availableGoalsNext}
                                onChange={(selected) => {
                                    setSelectedGoalNext(selected);
                                }}
                                defaultValue={selectedGoalNext || availableGoalsNext[0].value}
                                label="Store - Next Step"
                            />
                        </div>

                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <></>
                </div>

            </div>
        )
    }


    // -----------------------------------------------------------------------------------------------------------
    // render component
    // -----------------------------------------------------------------------------------------------------------
    return (

        <AppDialog
            open={true}
            size={"lg"}
            showSubmit={dialogMode !== 'view'}
            showCancel={true}
            title={`${dialogMode} item`}
            message={"Please enter the name of the new item"}
            onSubmit={handleDialogRequest}
            onCancel={onCancel}
        >
            <Grid container spacing={2}>
                {dialogMode !== 'view' && (
                    <Grid container style={{paddingBottom: '5px', borderBottom: '1px solid #ccc'}} spacing={1}>
                        <Grid item xs={1} sx={{marginLeft: '10px'}}>
                            <Typography variant="subtitle2" marginRight={"10px"}>Category</Typography>
                            <Select
                                fullWidth
                                size={"small"}
                                value={category}
                                label={"Category"}
                                title={"Category"}
                                variant={"outlined"}
                                onChange={event => setCategory(event.target.value)}
                            >
                                {uniqueCategories.map((value) => (
                                    <MenuItem key={value} value={value} id={value}>{value}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={4} sx={{margin: '0px', padding: '0px'}}>
                            <TextField
                                id="name"
                                size={"small"}
                                fullWidth
                                value={name}
                                sx={{marginTop: '16px'}}
                                disabled={dialogMode === 'delete' || dialogMode === 'view'}
                                label="Name"
                                error={name === ""}
                                variant="outlined"
                                onChange={event => setName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{margin: '0px', padding: '0px'}}>
                            <TextField
                                id="tag"
                                size={"small"}
                                fullWidth
                                value={tag}
                                sx={{marginTop: '16px'}}
                                disabled={dialogMode === 'delete' || dialogMode === 'view'}
                                label="Tag"
                                variant="outlined"
                                onChange={event => setTag(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{margin: '0px', padding: '0px'}}>
                            <TextField
                                id="sequence"
                                size={"small"}
                                fullWidth
                                value={sequence}
                                type={"number"}
                                sx={{marginTop: '16px'}}
                                disabled={dialogMode === 'delete' || dialogMode === 'view'}
                                label="Sequence"
                                variant="outlined"
                                onChange={event => setSequence(parseInt(event.target.value, 10))}
                            />
                        </Grid>
                        <Grid item xs={8} sx={{marginLeft: '10px', padding: '0px'}}>
                            <TextField
                                id="description"
                                fullWidth
                                size={"small"}
                                value={description}
                                disabled={dialogMode === 'delete' || dialogMode === 'view'}
                                label="Description"
                                variant="outlined"
                                onChange={event => setDescription(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{margin: '0px', padding: '0px'}}>
                        </Grid>
                    </Grid>
                )}

                {/* SHOW ASSISTANT BUTTON WHEN GOAL */}
                {category === 'Goals' && (
                    <Grid item xs={1} sx={{
                        marginTop: '0px', paddingLeft: '0 !important', borderRight: '1px solid #ccc',
                        textAlign: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            height: '100%',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <GoalDefinitionOptions categoryType={categoryType} setCategoryType={setCategoryType}/>
                            {categoryType === 'template' && (
                                <GoalFormAssistant onHandleInsert={handleInsert}/>
                            )}
                            {categoryType === 'settings' && (
                                <GoalDefinitionSettingsOptions onHandleSettingsMode={handleSettingsMode}
                                                               selectedMode={settingsMode}/>
                            )}

                        </div>

                    </Grid>
                )}
                <Grid item xs={category === 'Goals' ? 11 : 12} sx={{margin: '0px', paddingLeft: '16px'}}>
                    {categoryType === 'prompt' && (
                        <EditorViewer
                            editorState={editorStateValue}
                            showActions={false}
                            showToolbar={selectedRecord.value.category.toLowerCase() === 'templates'}
                            onEditorStateChange={state => handleEditorStateChange(state, "prompt")}
                            onSendRequest={() => {
                            }}
                            onUpdateEditorState={setEditorStateValue}
                        />
                    )}
                    {categoryType === 'template' && (
                        <EditorViewer
                            editorState={editorStateTemplate}
                            showActions={false}
                            showToolbar={false}
                            onEditorStateChange={state => handleEditorStateChange(state, "form")}
                            onSendRequest={() => {
                            }}
                            onUpdateEditorState={setEditorStateForm}
                        />
                    )}
                    {categoryType === 'settings' && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            {settingsMode === 'general' && (
                                <>
                                    {componentEnableGoal()}
                                    <Divider sx={{marginY: '20px'}}/>
                                    {componentSelectColor()}
                                    <Divider sx={{marginY: '20px'}}/>
                                    {componentSelectSystemPrompt()}
                                    <Divider sx={{marginY: '20px'}}/>
                                    {componentSelectTemplate()}
                                    <Divider sx={{marginY: '20px'}}/>
                                    {componentSelectLLM()}
                                </>
                            )}
                            {settingsMode === 'input' && (
                                <>
                                    {componentSelectTemplate()}
                                    <Divider sx={{marginY: '20px'}}/>
                                    {componentSelectInputWorkspace()}
                                </>
                            )}
                            {settingsMode === 'services' && (
                                <ComponentSelectZaiaServices
                                    knowledgeDomain={selectedKnowledgeDomain ? selectedKnowledgeDomain : "None"}
                                    knowledgeDomainFilter={selectedKnowledgeDomainFilter !== 'None' ? JSON.parse(selectedKnowledgeDomainFilter) : []}
                                    onKnowledgeDomainFilterChange={handleZaiaKnowledgeDomainFilter}
                                    onKnowledgeDomainChange={handleZaiaServicesChangeCategory}

                                />
                            )}
                            {settingsMode === 'output' && componentSelectOutput()}
                            {settingsMode === 'workflow' && componentSelectWorkflow()}
                        </div>
                    )}
                </Grid>
            </Grid>
        </AppDialog>
    );
};

export const getStoreItems = async (store: string, type: string) => {
    const items_in_store: IndexedDBRecord[] = await getAllFromIndexedDB(appSettings.database, store).then(
        // (app_theme: IndexedDBRecord[]) => {
        //     return app_theme.filter( (record: IndexedDBRecord) => record.value.category.toLowerCase() === 'goals' ).map((record) => ({
        //         label: record.value.name,
        //         value: record.value.name
        //     }));
        // }
    );
    let availableItems = items_in_store.filter((record: IndexedDBRecord) => record.value.category.toLowerCase() === type).map((record) => ({
        label: record.value.name,
        value: removeHTMLTags(record.value.value)
    }));

    availableItems.unshift({label: 'None', value: 'None'});
    return availableItems;
}



