import React, {useEffect, useState, useCallback} from "react";
import {Box, Button, Grid, List, ListItem, ListItemText, Slider, Typography} from "@mui/material";
import {
    appSettings,
    languageOptions,
    llmModelProvider,
    llmModelVersion,
    Option,
    saveSettings,
    selectedLanguage,
    setBackgroundColor,
    setLLMModelProvider,
    setLLMModelVersion,
    setSelectedCategory,
    setShowAssistantParameter,
    setShowEditor,
    setShowLinkedInParameter,
    setShowWatermark,
    showAssistantParameter,
    showEditor,
    showLinkedInParameter,
    showWatermark,
    trueFalse,
    updateSelectedLanguage, updateShowRAGData
} from "../../managers/generalManager";
import {SelectWithData} from "../general/Site.snippets";
import AppDialog from "../general/app_dialog";
import {PromptEditor} from "../prompt/promptEditor";
import {PersonaEditor} from "../persona/personaEditor";
import ColorPickButton, {Color, hexToRgbA, rgbAToHex} from "../general/color_button";
import {ColorResult} from "react-color";
import colorSchema from "../../app_theme/theme_support/colorSchema";

interface ConfigProps {
    isOpen: boolean;
    onClose: () => void;
}

const Option_Personas: React.FC = () => <PersonaEditor/>;
const Option_Prompts: React.FC = () => <PromptEditor/>;



const Option_General: React.FC = () => {
    const [temperature, setTemperature] = useState(appSettings.temperature);
    const [history_counter, setHistoryCounter] = useState(appSettings.history_counter);
    const [showRagData, setShowRagData] = useState(appSettings.showRAGData);
    const [backgroundColor, setNewBackgroundColor] = useState<Color>(hexToRgbA(appSettings.backgroundColor));

    useEffect(() => {
        appSettings.temperature = temperature;
    }, [temperature]);

    const handleTemperatureChange = useCallback((event: Event, newValue: number | number[]) => {
        const value = newValue as number;
        setTemperature(value);
        appSettings.temperature = value;
    }, []);

    const handleHistoryCounterChange = useCallback((event: Event, newValue: number | number[]) => {
        const value = newValue as number;
        setHistoryCounter(value);
        appSettings.history_counter = value;
    }, []);

    const handleLanguageSelected = useCallback((selected: Option) => {
        updateSelectedLanguage(selected.value);
    }, []);

    const handleShowRagData = useCallback((selected: Option) => {
        setShowRagData(selected.value === "true");
        updateShowRAGData(selected.value === "true");
    }, []);

    const handleCategorySelected = useCallback((selected: Option) => {
        setSelectedCategory(selected.value);
    }, []);

    const handleLlmModelVersion = useCallback((selected: Option) => {
        setLLMModelVersion(selected.value);
    }, []);

    const handleLlmModelProvider = useCallback((selected: Option) => {
        setLLMModelProvider(selected.value);
    }, []);

    const handleShowWatermark = useCallback((selected: Option) => {
        setShowWatermark(selected.value === "true");
    }, []);

    const handleShowAssistant = useCallback((selected: Option) => {
        setShowAssistantParameter(selected.value === "true");
    }, []);

    const handleShowEditor = useCallback((selected: Option) => {
        setShowEditor(selected.value === "true");
    }, []);

    const handleShowLinkedIn = useCallback((selected: Option) => {
        setShowLinkedInParameter(selected.value === "true");
    }, []);

    const handleSaveSettings = useCallback(() => {
        saveSettings();
        window.location.reload();
    }, []);

    const getCreativityLabel = useCallback((value: number): string => {
        if (value === 0) return "None";
        if (value <= 0.2) return "Minimaal";
        if (value <= 0.3) return "Normal";
        if (value <= 0.5) return "Average";
        if (value <= 0.7) return "Above average";
        if (value < 1) return "High";
        return "Maximal";
    }, []);

    const handleColorChange = useCallback((color: ColorResult) => {
        const {r, g, b, a} = color.rgb;
        setBackgroundColor(rgbAToHex({r, g, b, a: a !== undefined ? a : 1}));
        setNewBackgroundColor({r, g, b, a: a !== undefined ? a : 1});
    }, []);

    return (
        <>
            <div style={{maxWidth: '90%', marginLeft: '40px'}}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    marginBottom: '10px',
                    color: colorSchema.primary.main,
                }}>
                    <div style={{flex: '5', flexDirection: 'column'}}>
                        <p style={{width: '100%', textAlign: "start", marginRight: '10px'}}>Background Color</p>
                    </div>
                    <div style={{flex: '5', flexDirection: 'column', textAlign: 'start'}}>
                        <ColorPickButton
                            color={backgroundColor}
                            onChange={handleColorChange}/>

                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    marginBottom: '20px',
                    color: colorSchema.primary.main,
                }}>
                    <div style={{
                        display: 'flex',
                    }}>
                        <p style={{width: '100%', textAlign: "end", marginRight: '10px'}}>LLM MODEL</p>
                    </div>
                    <div style={{
                        display: 'flex',
                    }}>
                        <SelectWithData
                            sx={{marginRight: '2px'}}
                            data={llmModelProvider}
                            id="llmModelProvider"
                            onSelect={handleLlmModelProvider}
                            selected={llmModelProvider.findIndex((option) => option.value === appSettings.selectedLLMModelProvider) || 0}
                            label="Provider"
                            isOpen={false}
                        />

                        <SelectWithData
                            data={llmModelVersion}
                            id="llmModelVersion"
                            onSelect={handleLlmModelVersion}
                            selected={llmModelVersion.findIndex((option) => option.value === appSettings.selectedLLMModelVersion) || 0}
                            label="Version"
                            isOpen={false}
                        />
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    marginBottom: '20px',
                    color: colorSchema.primary.main,
                }}>
                    <div style={{
                        display: 'flex',
                    }}>
                        <p style={{width: '100%', textAlign: "end", marginRight: '10px'}}>
                            CREATIVITY ({getCreativityLabel(temperature)})
                        </p>
                    </div>
                    <Box sx={{width: 300}}>
                        <Box display="flex" alignItems="center">
                            <Slider
                                value={temperature}
                                onChange={handleTemperatureChange}
                                aria-labelledby="temperature-slider"
                                min={0} // Minimumwaarde van de slider
                                max={1} // Maximumwaarde van de slider
                                step={0.1}
                                style={{margin: '0 10px'}} // Voeg wat ruimte toe rond de slider
                            />
                        </Box>
                    </Box>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    marginBottom: '20px',
                    color: colorSchema.primary.main,
                }}>
                    <div style={{
                        display: 'flex',
                    }}>
                        <p style={{width: '100%', textAlign: "end", marginRight: '10px'}}>
                            HISTORY LEVEL ({history_counter})
                        </p>
                    </div>
                    <Box sx={{width: 300}}>
                        <Box display="flex" alignItems="center">
                            <Slider
                                value={history_counter}
                                onChange={handleHistoryCounterChange}
                                aria-labelledby="history-slider"
                                min={0} // Minimumwaarde van de slider
                                max={4} // Maximumwaarde van de slider
                                step={1}
                                style={{margin: '0 10px'}} // Voeg wat ruimte toe rond de slider
                            />
                        </Box>
                    </Box>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    marginBottom: '10px',
                    color: colorSchema.primary.main,
                }}>
                    <div style={{
                        display: 'flex',
                    }}>
                        <p style={{width: '100%', textAlign: "end", marginRight: '10px'}}>SHOW</p>
                    </div>
                    <div style={{
                        display: 'flex',
                    }}>
                        <SelectWithData
                            data={trueFalse}
                            sx={{width: '100px', marginRight: '2px'}}
                            id="SelectShowWatermark"
                            onSelect={handleShowWatermark}
                            selected={trueFalse.findIndex((option) => option.value === (showWatermark ? "true" : "false")) || 0}
                            label="Watermark"
                            isOpen={false}
                        />
                        <SelectWithData
                            data={trueFalse}
                            sx={{width: '100px', marginRight: '2px'}}
                            id="SelectShowAssistant"
                            onSelect={handleShowAssistant}
                            selected={trueFalse.findIndex((option) => option.value === (showAssistantParameter ? "true" : "false")) || 0}
                            label="Assistant"
                            isOpen={false}
                        />

                        <SelectWithData
                            data={trueFalse}
                            sx={{width: '100px'}}
                            id="SelectShowEditor"
                            onSelect={handleShowEditor}
                            selected={trueFalse.findIndex((option) => option.value === (showEditor ? "true" : "false")) || 0}
                            label="Editor"
                            isOpen={false}
                        />

                        <SelectWithData
                            data={trueFalse}
                            sx={{width: '100px', marginRight: '2px'}}
                            id="SelectLinkedInAssistant"
                            onSelect={handleShowLinkedIn}
                            selected={trueFalse.findIndex((option) => option.value === (showLinkedInParameter ? "true" : "false")) || 0}
                            label="LinkedIn"
                            isOpen={false}
                        />

                    </div>
                </div>
                {/* FEEDBACK LANGUAGE */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    marginBottom: '10px',
                    color: colorSchema.primary.main,
                }}>
                    <div style={{
                        display: 'flex',
                    }}>
                        <p style={{width: '100%', textAlign: "end", marginRight: '10px'}}>FEEDBACK LANGUAGE</p>
                    </div>
                    <div style={{
                        display: 'flex',
                    }}>
                        <SelectWithData
                            data={languageOptions}
                            id="SelectFeedbackLanguage"
                            onSelect={handleLanguageSelected}
                            selected={languageOptions.findIndex((option) => option.value === selectedLanguage) || 0}
                            label=""
                            isOpen={false}
                        />
                    </div>
                </div>
                 {/* SHOW KB CHUNKS */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    marginBottom: '10px',
                    color: colorSchema.primary.main,
                }}>
                    <div style={{
                        display: 'flex',
                    }}>
                        <p style={{width: '100%', textAlign: "end", marginRight: '10px'}}>SHOW RAG DATA</p>
                    </div>
                    <div style={{
                        display: 'flex',
                    }}>
                        <SelectWithData
                            data={trueFalse}
                            sx={{width: '100px', marginRight: '2px'}}
                            id="selectRagData"
                            onSelect={handleShowRagData}
                            selected={trueFalse.findIndex((option) => option.value === (showRagData ? "true" : "false")) || 0}
                            label="RAGData"
                            isOpen={false}
                        />
                    </div>
                </div>
            </div>
            <div style={{textAlign: "end", marginTop: '20px'}}>
                <Button
                    variant={"contained"} color={"warning"} onClick={() => {
                    handleSaveSettings();
                }}>
                    Use Selected Settings
                </Button>
            </div>
        </>
    );
};

const options: { label: string; component: React.FC }[] = [
    {label: 'Options', component: Option_General},
    {label: 'Personas', component: Option_Personas},
    {label: 'Prompts', component: Option_Prompts},
];

// --- Config Component ---
// Main component for the Config dialog. This component renders the dialog with the selected option.
// Implemented options are : Options, Personas, Prompts.
// Config state is stored in the parent component and passed as props to this component.
// -----------------------------------------------------------------------------------------------------------------------
const Config: React.FC<ConfigProps> = ({isOpen, onClose}) => {
    const [selectedOptionLabel, setSelectedOptionLabel] = useState<string>("Options");

    const handleClick = useCallback((optionLabel: string) => {
        setSelectedOptionLabel(optionLabel);
    }, []);

    const handleSaveSettings = useCallback(() => {
        saveSettings();
        onClose();
        // window.location.reload();
    }, []);

    // Render the selected option component.
    const renderSelectedOption = useCallback(() => {
        switch (selectedOptionLabel) {
            case 'Options':
                return <Option_General/>;
            case 'Personas':
                return <Option_Personas/>;
            case 'Prompts':
                return <Option_Prompts/>;
            default:
                return <Typography>Select an option</Typography>;
        }
    }, [selectedOptionLabel]);

    return (
        <AppDialog
            open={isOpen}
            size="md"
            title="Settings"
            message=""
            onSubmit={() => {
            }}
            onCancel={onClose}
        >
            <Grid container spacing={2}>
                {/* left side, overview of different option segments  */}
                <Grid item xs={3}>
                    <List component="nav" aria-label="store list" sx={{flex: 1, paddingX: 2}}>
                        {options.map((option, index) => (
                            <ListItem
                                key={index}
                                onClick={() => handleClick(option.label)}
                                sx={{
                                    borderRadius: '5px',
                                    height: '40px',
                                    textAlign: 'center',
                                    marginBottom: '5px',
                                    backgroundColor: selectedOptionLabel === option.label ? colorSchema.background.selected : "inherit",
                                    color: selectedOptionLabel === option.label ? 'white' : "inherit",
                                    fontWeight: selectedOptionLabel === option.label ? 'bold' : "inherit",
                                    '&:hover': {
                                        backgroundColor: colorSchema.action.hover,
                                    },
                                }}
                            >
                                <ListItemText primary={option.label}/>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                {/* right side, selected option component */}
                <Grid item xs={9} style={{marginTop: '15px', borderLeft: '2px solid #000'}}>
                    {renderSelectedOption()}
                </Grid>
                {/*<div style={{textAlign: "end", marginTop: '20px'}}>*/}
                {/*    <Button*/}
                {/*        variant={"contained"} color={"warning"} onClick={() => {*/}
                {/*        handleSaveSettings();*/}
                {/*    }}>*/}
                {/*        Use Selected Settings*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </Grid>
        </AppDialog>
    );
};

export default Config;

