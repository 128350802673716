// LocalDataStore.tsx
import React, {useEffect, useMemo, useState} from 'react';
import StoreDialogs from './store_dialogs';
import GroupManager from "./components/group_manager";
import GroupItemManager from "./components/group_item_manager";
import AppDialog from "../general/app_dialog";
import {
    addDBStore, DBItem,
    deleteDBStore,
    exportDBToJson,
    getObjectStoresInDatabase,
    importDBFromJson,
} from "../../utils/IndexedDB.utils";

import {useDispatch, useSelector} from "react-redux";
// import {AppState} from "../../store/old/store";
// import {setActiveStore} from "../../store/old/data-slice";
import {appSettings, DefaultCategories} from "../../managers/generalManager";
import {fetchData} from "pdfjs-dist";
import logger from "../../utils/logging_services";
import {RootState} from "../../store/store";
import {setActiveStore} from "../../store/dataSlice";

export interface LocalDataStoreProps {
    isOpen: boolean;
    filter: string;
    mode: "select" | "manage" | "add";
    mode_data: string;
    dbName: string;
    onClose: () => void;
    onSelect: (data: string) => void;
}


// Task group management component
// General entry point for managing app_theme stores and task items ( Goals, Prompts, etc.)
// The current implemenation uses indexedDB for app_theme storage
//
// The component is divided into two main sections:
// 1. StoreList: Displays a list of available app_theme stores and provides options to add, delete, save and load app_theme stores
// 2. StoreData: Displays the app_theme items within the selected app_theme store. The app_theme items are filtered based on the selected category
// --------------------------------------------------------------------------------------------
export const TaskGroupsManager: React.FC<LocalDataStoreProps> = (props) => {
    const [showCreateStoreDialog, setShowCreateStoreDialog] = useState<boolean>(false);
    const [showDeleteStoreDialog, setShowDeleteStoreDialog] = useState<boolean>(false);
    const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [newStoreName, setNewStoreName] = useState<string>("");
    const [storeList, setStoreList] = useState<string[]>([])
    const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);

    const dispatch = useDispatch();
    const selectedStore = useSelector((state: RootState) => state.data.activeStore);

    useEffect(() => {

            const initializeData = async () => {
                const stores = await getObjectStoresInDatabase(props.dbName);
                if (stores.length > 0) {
                    setStoreList(stores);
                    const newSelectedStore = stores.includes(selectedStore) ? selectedStore : stores[0];
                    dispatch(setActiveStore(newSelectedStore))
                    fetchData(stores);
                }
            };

            let categories = null;
            categories = Object.values(DefaultCategories);
            setUniqueCategories(Array.from(categories));

            initializeData();
        }, []
    )

    const handleAddDataStoreSubmit = () => {

        if (!newStoreName.trim()) {
            logger.info("Store name is required")
            setError('Store name is required');
            setShowCreateStoreDialog(true);
            return false;
        }

        setShowPleaseWait(true);
        addDBStore(props.dbName, newStoreName).then(() => {
            setShowCreateStoreDialog(false);
            setStoreList([...storeList, newStoreName]);
            setShowPleaseWait(false);
            dispatch(setActiveStore(newStoreName))
            setNewStoreName("")
        });
    }

    const handleAddDataStoreCancel = () => {
        setShowCreateStoreDialog(false);
        setNewStoreName("");
    };

    const handleDeleteCurrentStore = () => {
        setShowPleaseWait(true);
        deleteDBStore(props.dbName, selectedStore).then(() => {
            setShowDeleteStoreDialog(false);
            const newStoreList = storeList.filter(store => store !== selectedStore);
            setStoreList(prevState => newStoreList);
            if (newStoreList.length > 0) {
                dispatch(setActiveStore(newStoreList[0]))
            } else {
                dispatch(setActiveStore(""));
            }
            setShowPleaseWait(false);
        });
    }

    const handleShowCreateStoreDialog = () => {
        setShowCreateStoreDialog(true);
    };

    const handleShowConfirmDeleteStore = () => {
        setShowDeleteStoreDialog(true);
    };

    const handleSaveStore = () => {
        exportDBToJson(props.dbName, selectedStore).then((result) => {
            logger.info("Exported app_theme", result);
        })
    }

    const handleLoadStore = () => {
        importDBFromJson(props.dbName).then((result) => {
            logger.info("Imported app_theme", result);
        })
    }

    return (
        <AppDialog open={true} size={"xl"} title={"Task Group Management"} message={error} showSubmit={true}
                   showCancel={false} onSubmit={props.onClose} onCancel={props.onClose}>
            <div style={{display: 'flex', flexDirection: 'row', flex: 1, minHeight: appSettings.dialogHeight}}>
                <GroupManager
                    storeList={storeList}
                    onAddStore={handleShowCreateStoreDialog}
                    onDeleteStore={handleShowConfirmDeleteStore}
                    onSaveStore={handleSaveStore}
                    onLoadStore={handleLoadStore}
                />
                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <GroupItemManager dbName={props.dbName} defaultFilter={'Goals'} uniqueCategories={uniqueCategories}/>
                    <StoreDialogs
                        showCreateStoreDialog={showCreateStoreDialog}
                        showDeleteStoreDialog={showDeleteStoreDialog}
                        showPleaseWait={showPleaseWait}
                        error={error}
                        newStoreName={newStoreName}
                        setNewStoreName={(storeName) => setNewStoreName(storeName.charAt(0).toUpperCase() + storeName.slice(1))}
                        handleAddDataStoreSubmit={handleAddDataStoreSubmit}
                        handleAddDataStoreCancel={handleAddDataStoreCancel}
                        handleDeleteCurrentStore={handleDeleteCurrentStore}
                        setShowDeleteStoreDialog={setShowDeleteStoreDialog}
                    />
                </div>
            </div>
        </AppDialog>
    );
};

export default TaskGroupsManager;


