import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AppTable, {TableColumn, TableItem} from '../../general/app_table';
import {
    getAllFromIndexedDB,
    getObjectStoresInDatabase,
    IndexedDBRecord,
    DBItem, deleteItemFromStore, addItemToStore
} from '../../../utils/IndexedDB.utils';
import {StoreDataDetail} from "../store_data_detail";
import AppDialog from "../../general/app_dialog";
import {Typography} from "@mui/material";
import {Option, setCopiedTemplate} from "../../../managers/generalManager";
import logger from "../../../utils/logging_services";
import {RootState} from "../../../store/store";
import {setActiveStore} from "../../../store/dataSlice";

interface StoreDataProps {
    dbName: string;
    defaultFilter: string;
    uniqueCategories: string[];
}

interface ObjectItem extends TableItem {
    key: string;
    name: string;
    description: string;
    item?: DBItem;
}

// StoreData component
// Main component to show the different items in the selected store.
// -------------------------------------------------------------------------------------------------------------------
const GroupItemManager: React.FC<StoreDataProps> = ({dbName, defaultFilter, uniqueCategories}) => {
    const [recordList, setRecordList] = useState<IndexedDBRecord[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = useState<IndexedDBRecord | null>(null);
    const [dialogMode, setDialogMode] = useState<string>('');
    const [categoryFilter, setCategoryFilter] = useState<string>(defaultFilter);
    const [showDeleteItem, setShowDeleteItem] = useState<boolean>(false);
    const [sortedRecords, setSortedRecords] = useState<ObjectItem[]>([]);
    const [availableTemplates, setAvailableTemplates] = useState<string[]>([]);
    const [availablePrompts, setAvailablePrompts] = useState<Option[]>([]);

    const dispatch = useDispatch();
    const selectedStore = useSelector((state: RootState) => state.data.activeStore);

    useEffect(() => {
        setSortedRecords(
            [...recordList]
                .sort((a, b) => a.value.name.localeCompare(b.value.name))
                .map((record) => ({
                    key: record.key,
                    category: record.value.category,
                    name: record.value.name,
                    description: record.value.description,
                    item: record.value
                }))
        );

        setAvailableTemplates([
            "None",
            ...recordList
                .filter((a) => a.value.category === "Templates")
                .map((record) => (record.value.name))
        ]);

        setAvailablePrompts([
            {value: "None", label: "None"},
           ...recordList
                .filter((a) => a.value.category === "Prompts")
                .map((record) => ({label: record.value.name, value: record.value.value}))
        ]);
    }, [recordList]);

    // when selected store changes, fetch the app_theme from the indexedDB
    useEffect(() => {
        let isSubscribed = true;
        if (selectedStore === "") {
            return;
        }

        const fetchData = async (stores: string[]) => {
            try {
                if (!stores.includes(selectedStore)) {
                    return;
                }

                const data = await getAllFromIndexedDB(dbName, selectedStore);
                if (isSubscribed) {
                    setRecordList(data);
                }
            } catch (error) {
                if (isSubscribed) {
                    setRecordList([]);
                }
            } finally {
                if (isSubscribed) {
                    setIsLoaded(true);
                }
            }
        };

        const initializeData = async () => {
            const stores = await getObjectStoresInDatabase(dbName);
            if (stores.length > 0) {
                const newSelectedStore = stores.includes(selectedStore) ? selectedStore : stores[0];
                dispatch(setActiveStore(newSelectedStore));
                fetchData(stores).then(() => {
                });
            }
        };

        initializeData().then(() => {
        });
        return () => {
            isSubscribed = false;
        };
    }, [selectedStore]);

    // column formatter to show the app_theme in AppTable
    const columns: TableColumn<ObjectItem>[] = [
        {
            title: "Name",
            dataIndex: "name",
            render: (item: ObjectItem) => item.name
        },
        {
            title: "Tag",
            dataIndex: "Tag",
            render: (item: ObjectItem) => item.item ? item.item.tag || "" : ""
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (item: ObjectItem) => item.description
        },
    ];

    // request handler to show the dialog for add, edit or delete
       const handleRequest = (action: string, key: string, selectedColumns: string[]) => {
        let selectedRecord: IndexedDBRecord | null = null;

        if (action !== 'add') {
            selectedRecord = recordList.find(item => item.key === key) || null;
            if (!selectedRecord) {
                logger.error("Record not found", key);
                return;
            }
        }

        if (action === 'copy' || action === 'copy to') {
            if (selectedRecord) {
                const newRecord: IndexedDBRecord = {
                    key: "",
                    value: {
                        ...selectedRecord.value,
                        id: 0,
                        name: `${selectedRecord.value.name} - Copy`
                    }
                };
                setSelectedRecord(newRecord);
                if (action === 'copy') {
                    setDialogMode('add');
                } else {
                    setCopiedTemplate(newRecord);
                }
            }
        } else {
            setSelectedRecord(selectedRecord || {
                key: "",
                value: {
                    id: 0,
                    category: "",
                    name: "",
                    description: "",
                    value: "",
                    form: "",
                    llmModelProvider: "",
                    llmModelVersion: "",
                    workspaceShared: "",
                    workspacePersonal: "",
                    workspaceContentType: "",
                    tag: "",
                    removePreselectedWorkspaceItems: false,
                    removePreselectedWorkspaceItemsOnClose: false,
                    template: "",
                    outputFormat: "",
                    systemPrompt: ""
                }
            });
            setDialogMode(action === 'delete' ? '' : action);
            setShowDeleteItem(action === 'delete');
        }
    };

    const handleCancel = () => {
        setSelectedRecord(null);
        setDialogMode('');
    };

    const handleDialogSubmit = (record: IndexedDBRecord) => {

        if (selectedRecord && selectedRecord.key !== "") {
            handleRemoveItem(selectedRecord.key, record).then(() => {
                handleAddUpdateItem(record).then(() => {
                });
            });
        } else {
            handleAddUpdateItem(record).then(() => {
            });
        }

        setSelectedRecord(null);
        setDialogMode('');
    };

    const handleAddUpdateItem = async (record: IndexedDBRecord) => {
        try {

            console.log("Adding/Updating record", record);
            let updatedRecords: IndexedDBRecord[] = [...recordList];

            // Remove record based on key to avoid duplicates
            if (selectedRecord && selectedRecord.key !== "") {
                updatedRecords = updatedRecords.filter(r => r.key !== selectedRecord.key);
            }

            // New key
            const key = `${record.value.category}_${record.value.name}`;

            // Add new record
            updatedRecords.push({
                key,
                value: {
                    id: record.value.id !== 0 ? record.value.id : Date.now(),
                    name: record.value.name,
                    description: record.value.description,
                    enable: record.value.enable || 'Yes',
                    value: record.value.value,
                    form: record.value.form,
                    category: record.value.category,
                    template: record.value.template,
                    tag: record.value.tag || "",
                    sequence: record.value.sequence || 0,
                    llmModelProvider: record.value.llmModelProvider || "None",
                    llmModelVersion: record.value.llmModelVersion || "None",
                    workspaceShared: record.value.workspaceShared || "None",
                    workspacePersonal: record.value.workspacePersonal || "None",
                    workspaceContentType: record.value.workspaceContentType || "None",
                    removePreselectedWorkspaceItemsOnClose: record.value.removePreselectedWorkspaceItemsOnClose || false,
                    removePreselectedWorkspaceItems: record.value.removePreselectedWorkspaceItems || false,
                    outputFormat: record.value.outputFormat || undefined,
                    systemPrompt: record.value.systemPrompt || undefined,
                    knowledgeDomain: record.value.knowledgeDomain || undefined,
                    knowledgeDomainFilter: record.value.knowledgeDomainFilter || undefined,

                    csvHeaderFields: record.value.csvHeaderFields || undefined,
                    csvCategory: record.value.csvCategory || undefined,
                    csvFile: record.value.csvFile || undefined,
                    backgroundColour: record.value.backgroundColour || undefined,
                }
            });

            setRecordList(prevState => updatedRecords);
            setSelectedRecord(null);
            setDialogMode('');

            // Update IndexedDB
            addItemToStore(dbName, selectedStore, key, record.value).then(() => {
            });
        } catch (error) {
            console.log("Error during add/update", error);
        }
    };

    const handleRemoveItem = async (key: string, record: IndexedDBRecord) => {
        console.log("Removing record", record);
        await deleteItemFromStore(dbName, selectedStore, key);
        setRecordList(recordList.filter((item) => item.key !== key));
    };

    const handleConfirmDelete = () => {
        if (selectedRecord) {
            handleRemoveItem(selectedRecord.key, selectedRecord).then(() => {
                setSelectedRecord(null);
                setShowDeleteItem(false);
            });
        }
    }

    return (
        <div>
            {isLoaded && (
                <AppTable
                    data={sortedRecords}
                    title={""}
                    filter={categoryFilter}
                    filterValues={uniqueCategories}
                    columns={columns}
                    selectColumnsToShow={[]}
                    hideHeaders={false}
                    menu={[{label: "Copy", action: "copy"},{label: "Copy To Memory", action: "copy to"},{label: "Delete", action: "delete"}]}
                    menuTable={[]}
                    selected={[]}
                    allowAdd={true}
                    onRequest={handleRequest}
                    mode="manage"
                    selectMode="edit"
                    onFilterSelected={setCategoryFilter}
                    allowDelete={true}
                    allowEdit={true}
                    allowView={true}
                />
            )}
            {selectedRecord && dialogMode && (
                <StoreDataDetail
                    activeRecord={selectedRecord}
                    availableTemplates={availableTemplates}
                    availablePrompts={availablePrompts}
                    dialogMode={dialogMode}
                    uniqueCategories={uniqueCategories}
                    defaultCategory={categoryFilter}
                    onSubmitted={handleDialogSubmit}
                    onCancel={handleCancel}
                />
            )}
            {showDeleteItem && (
                <AppDialog open={true} size={"sm"} showSubmit={true} showCancel={true}
                           title={`Delete ${selectedRecord?.value.name}`}
                           message={"Are you sure you want to delete the selected item?"} onSubmit={handleConfirmDelete}
                           onCancel={() => setShowDeleteItem(false)}>
                    <div style={{textAlign: 'center'}}>
                        <Typography variant="h6" sx={{marginTop: '10px', marginLeft: '10px'}}>
                            You're about to delete the task item {selectedRecord?.value.name}.
                        </Typography>
                        <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
                            Please be aware that this action is permanent and cannot be undone.
                        </Typography>
                        <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
                            Deleting the store will permanently remove all data contained within it.
                        </Typography>
                        <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
                            To confirm, click Confirm.
                        </Typography>
                    </div>
                </AppDialog>
            )}
        </div>
    );
};

export default GroupItemManager;
