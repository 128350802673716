// IndexedDBUtils.ts

import {appSettings} from "../managers/generalManager";
import logger from "./logging_services";
import {ObjectItem} from "../components/files/file_navigator";
import {getCurrentDayOfYear} from "./common_tools";

interface Color {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface DBItem {
    id: number;
    category: string;
    name: string;
    description: string;
    value: string;
    form?: string;
    template?: string;
    tag?: string;
    sequence?: number;
    enable?: string;
    systemPrompt?: string;
    outputFormat?: string;
    llmModelProvider?: string;
    llmModelVersion?: string;
    workspacePersonal?: string;
    workspaceShared?: string;
    workspaceContentType?: string;
    removePreselectedWorkspaceItems?: boolean;
    removePreselectedWorkspaceItemsOnClose?: boolean;
    csvHeaderFields?: {[key: string]: string};
    csvCategory?: string;
    csvFile?: ObjectItem;
    backgroundColour?: string;
    knowledgeDomain?: string;
    knowledgeDomainFilter?: string;
}

export interface IndexedDBRecord {
    key: string;
    value: DBItem;
}

export const getCurrentDBVersion = async (dbName: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        const openReq = indexedDB.open(dbName);
        openReq.onsuccess = () => {
            const db = openReq.result;
            resolve(db.version);
            db.close();
        };
        openReq.onerror = () => reject(openReq.error);
    });
};

export const initDB = async (dbName: string): Promise<void> => {
    try {
        await openDB(dbName);

        const isPersistent = await navigator.storage.persist();
        if (isPersistent) {
            logger.info(`${dbName} database is now marked as persistent.`);
        } else {
            logger.info(`Unable to mark ${dbName} database as persistent.`);
        }
    } catch (error) {
        if (error instanceof Error) {
            logger.error(`Error initializing ${dbName} database:`, error);
        }
    }
};

export const openDB = async (dbName: string, storeNames: string[] = [], allowUpdate: boolean = false): Promise<IDBDatabase> => {
    let currentVersion = allowUpdate ? await getCurrentDBVersion(dbName) + 1 : undefined;

    return new Promise((resolve, reject) => {
        const openReq = indexedDB.open(dbName, currentVersion);
        openReq.onupgradeneeded = (event) => {
            const db = openReq.result;
            for (const storeName of storeNames) {
                if (!db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName);
                }
            }
        };
        openReq.onsuccess = () => resolve(openReq.result);
        openReq.onerror = () => reject(openReq.error);
    });
};


export const getItemFromStore = async <T = any>(dbName: string, storeName: string, key: string): Promise<T> => {
    const db = await openDB(dbName, [storeName]);
    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);

    return new Promise((resolve, reject) => {
        const getRequest = store.get(key);
        getRequest.onsuccess = () => resolve(getRequest.result);
        getRequest.onerror = () => reject(getRequest.error);
        tx.oncomplete = tx.onabort = tx.onerror = () => db.close();
    });
};

export const addItemToStore = async <T = any>(dbName: string, storeName: string, key: string, value: T): Promise<void> => {
    const db = await openDB(dbName, [storeName], true);
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);

    return new Promise((resolve, reject) => {
        const putRequest = store.put(value, key);
        putRequest.onerror = () => reject(putRequest.error);
        tx.oncomplete = () => resolve();
        tx.onerror = () => reject(tx.error);
        tx.oncomplete = tx.onabort = tx.onerror = () => db.close();
    });
};

export const deleteItemFromStore = async (dbName: string, storeName: string, key: string) => {
    const db = await openDB(dbName, [storeName], true);
    const tx = db.transaction(storeName, 'readwrite');
    tx.objectStore(storeName).delete(key);

    return new Promise<void>((resolve, reject) => {
        tx.oncomplete = () => resolve();
        tx.onerror = () => reject(tx.error);
        tx.onabort = () => {
            db.close();
        };
    });
};

export const addDBStore = async (dbName: string, storeName: string): Promise<void> => {
    const db = await openDB(dbName, [storeName], true);
    db.close();
};

export const deleteDBStore = async (dbName: string, storeName: string): Promise<void> => {
    const currentVersion = await getCurrentDBVersion(dbName);
    const newVersion = currentVersion + 1;

    return new Promise((resolve, reject) => {
        const openReq = indexedDB.open(dbName, newVersion);

        openReq.onupgradeneeded = (event) => {
            const db = openReq.result;
            logger.info("Upgrading database for deletion", db);
            if (db.objectStoreNames.contains(storeName)) {
                db.deleteObjectStore(storeName);
                logger.info(`Store ${storeName} deleted`);
            }
        };

        openReq.onsuccess = () => {
            const db = openReq.result;
            db.close();
            resolve();
        };

        openReq.onerror = () => {
            reject(openReq.error);
        };
    });
};

export async function getObjectStoresInDatabase(dbName: string): Promise<string[]> {
    try {
        const db = await openDB(dbName, [], false);
        const objectStoreNames = Array.from(db.objectStoreNames);
        db.close();
        return objectStoreNames;
    } catch (e) {
        if (e instanceof Error) {
            logger.error("Error fetching object store names:", e);
        }
        return []; // Returning an empty array in case of an error
    }
}

export const getAllFromIndexedDB = async (dbName: string, storeName: string): Promise<any[]> => {
    const db = await openDB(dbName, [storeName]);
    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);
    const items: any = [];

    return new Promise<any[]>((resolve, reject) => {
        store.openCursor().onsuccess = function (event: any) {
            const cursor = event.target.result;
            if (cursor) {
                // push key and value to items array
                items.push({key: cursor.key, value: cursor.value});
                cursor.continue();
            } else {
                tx.oncomplete = () => {
                    db.close();
                    resolve(items);
                };
                tx.onerror = (event) => {
                    db.close();
                    reject(event.target);
                };
            }
        };
    });
};

export async function getItemsByCategory(category: string, store: string = 'General'): Promise<DBItem[]> {

    if ( store === "" ) {
        logger.info("In getItemsByCategory - store is empty")
        return [];
    }

    // Assuming appState.task_groups contains the name of the database and selectedStore the name of the store
    const currentDbName = appSettings.database; // Name of the database
    logger.info("In getItemsByCategory - category: ", category)

    // convert the first letter of store to uppercase
    store = store.charAt(0).toUpperCase() + store.slice(1);

    try {
        const records = await getAllFromIndexedDB(currentDbName, store);

        const filteredItems: DBItem[] = records
            .filter(record => record.value.category === category)
            .map(record => {
                const cleanedValue = {...record.value};
                cleanedValue.value = cleanedValue.value
                    .replace(/<\/?[^>]+(>|$)/g, "")
                    .replace(/&nbsp;/g, " ")
                    .trim();
                return cleanedValue as DBItem;
            });
        return filteredItems;

    } catch (error) {
        // create an error with input information and error
        logger.info("In getItemsByCategory - error: ", error + ' - ' + category + ' - ' + store);
        return [];
    }
}

export const exportDBToJson = async (dbName: string, storeName: string ): Promise<void> => {
    const db = await openDB(dbName);

    const exportData: Record<string, Record<string, any>> = {};
    let storeNames: string[] = [];
    if ( storeName === "" ) {
        storeNames = Array.from(db.objectStoreNames);
    } else {
        storeNames = [storeName];
    }

    for (const storeName of storeNames) {
        const tx = db.transaction(storeName, 'readonly');
        const store = tx.objectStore(storeName);
        const allRecords: Record<string, any> = {};

        await new Promise<void>((resolve, reject) => {
            const cursorRequest = store.openCursor();
            cursorRequest.onsuccess = (event) => {
                const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
                if (cursor) {
                    allRecords[cursor.key as string] = cursor.value;
                    cursor.continue();
                } else {
                    // No more entries
                    resolve();
                }
            };
            cursorRequest.onerror = () => reject(cursorRequest.error);
        });

        exportData[storeName] = allRecords;
    }

    db.close();

    // Assuming you have a way to workspace this JSON object to a file
    const jsonStr = JSON.stringify(exportData, null, 2);

    const today = getCurrentDayOfYear();

    if ( storeName === "" ) {
        await saveJsonToFile(jsonStr, `${dbName}_all_${today}.json`);
    } else {
        await saveJsonToFile(jsonStr, `${dbName}_${storeName}_${today}.json`);
    }
};

export async function saveJsonToFile(jsonStr: string, fileName: string) {
    // Check for File System Access API support
    if ('showSaveFilePicker' in window) {
        try {
            // Options for the workspace file picker
            const options = {
                types: [{
                    description: 'JSON Files',
                    accept: {'application/json': ['.json']},
                }],
                suggestedName: fileName,
            };
            // Show the workspace file picker
            // @ts-ignore
            const handle = await window.showSaveFilePicker(options);
            // Create a FileSystemWritableFileStream to write to
            // @ts-ignore
            const writableStream = await handle.createWritable();
            // Write the contents of the JSON string to the file
            await writableStream.write(jsonStr);
            // Close the file and write the contents to disk
            await writableStream.close();
            logger.info('File saved successfully.');
        } catch (error) {
            // Handle errors or user cancellation
            if (error instanceof Error) {
                logger.error('Error saving file:', error);
            }
        }
    } else {
        // Fallback method or alert for unsupported browsers
        logger.info('The File System Access API is not supported in this browser.');
        // As a fallback, you could implement a download link method or other alternatives
    }
}

export const importDBFromJson = async (dbName: string) => {
    // Check for File System Access API support
    if ('showOpenFilePicker' in window) {
        try {
            logger.info('Importing app_theme from JSON file...');
            const options = {
                types: [{
                    description: 'JSON Files',
                    accept: {'application/json': ['.json']},
                }],
            };
            // @ts-ignore
            const [fileHandle] = await window.showOpenFilePicker(options);
            const file = await fileHandle.getFile();
            const jsonStr = await file.text();
            const importData: Record<string, Record<string, any>> = JSON.parse(jsonStr);

            // Collect store names from the import app_theme
            const storeNames = Object.keys(importData);

            // Open the database and create missing stores if necessary
            const db = await openDB(dbName, storeNames, true);

            for (const storeName of storeNames) {
                const tx = db.transaction(storeName, 'readwrite');
                const store = tx.objectStore(storeName);
                const records = importData[storeName];

                for (const [key, value] of Object.entries(records)) {
                    // Here we directly put the value in the store
                    await store.put(value, key);
                }

                await new Promise<void>((resolve, reject) => {
                    tx.oncomplete = () => resolve();
                    tx.onerror = () => reject(tx.error);
                });
            }

            db.close();
            logger.info('Data imported successfully.');
        } catch (error) {
            if (error instanceof Error) {
                logger.error('Error loading file:', error);
            }
        }
    } else {
        logger.error('The File System Access API is not supported in this browser.');
    }
};

// export const importDBFromJson = async (dbName: string) => {
//     // Check for File System Access API support
//     if ('showOpenFilePicker' in window) {
//         try {
//             console.log('Importing app_theme from JSON file...')
//             const options = {
//                 types: [{
//                     description: 'JSON Files',
//                     accept: {'application/json': ['.json']},
//                 }],
//             };
//             // @ts-ignore
//             const [fileHandle] = await window.showOpenFilePicker(options);
//             const file = await fileHandle.getFile();
//             const jsonStr = await file.text();
//             const importData: Record<string, Record<string, any>> = JSON.parse(jsonStr);
//
//             const db = await openDB(dbName, "", true);
//
//             for (const storeName of Object.keys(importData)) {
//                 const tx = db.transaction(storeName, 'readwrite');
//                 const store = tx.objectStore(storeName);
//                 const records = importData[storeName];
//
//                 for (const [key, value] of Object.entries(records)) {
//                     // Here we directly put the value in the store
//                     // The key is already included in the value object, assuming the export included it
//                     // If your value objects don't contain their keys, you might need to adjust this logic
//                     await store.put(value, key);
//                 }
//
//                 await new Promise<void>((resolve, reject) => {
//                     tx.oncomplete = () => resolve();
//                     tx.onerror = () => reject(tx.error);
//                 });
//             }
//
//             db.close();
//             console.log('Data imported successfully.');
//         } catch (err) {
//             console.error('Error loading file:', err);
//         }
//     } else {
//         console.log('The File System Access API is not supported in this browser.');
//     }
// };
