import React, {useState, useEffect} from 'react';
import {
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    TextField,
    Select,
    MenuItem,
    InputAdornment,
    Paper,
    Divider,
    IconButton,
    Tooltip,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContentText,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material';
import {
    Edit as EditIcon,
    Save as SaveIcon,
    NavigateNext as NextIcon,
    NavigateBefore as PreviousIcon,
    Close as CloseIcon,
    Cancel as CancelIcon,
    ViewList as ListIcon,
    CropSquare as RecordIcon,
} from '@mui/icons-material';
import AppTable from "../../general/app_table";
import {DynamicDataItem} from "../csv_table";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import colorSchema from "../../../app_theme/theme_support/colorSchema";
import logger from "../../../utils/logging_services";
import {addRemoveSnippetToKb} from "../../../backend/snippet.services";
import {appSession} from "../../../managers/generalManager";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {WorkspaceItem} from "../../../store/types";
// import {AppState} from "../../../store/old/store";  // Make sure to import AppTable from the correct path

interface RecordViewerProps {
    records: DynamicDataItem[];
    open: boolean;
    isModalMode: boolean;
    onClose: () => void;
    onSave: (record: DynamicDataItem) => Promise<void>;
    columns: any[];  // Add this prop for AppTable
    selectedFile?: { columns: string[] };  // Add this prop for AppTable
    itemRef?: WorkspaceItem;  // Add this prop for AppTable
    menuItem?: any;  // Add this prop for AppTable
    menuTable?: any;  // Add this prop for AppTable
    selectedKeys: string[];  // Add this prop for AppTable
    onRecordViewed?: (record: DynamicDataItem | undefined) => void;
    handleRequest?: (action: string, key: string, selectedColumns: string[]) => Promise<void>;  // Add this prop for AppTable
}

const RecordViewer = ({
                          open,
                          records,
                          isModalMode,
                          columns,
                          selectedFile,
                          itemRef,
                          menuItem,
                          menuTable,
                          selectedKeys,
                          handleRequest,
                          onRecordViewed,
                          onClose,
                          onSave
                      }: RecordViewerProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [editedRecord, setEditedRecord] = useState<DynamicDataItem>({} as DynamicDataItem);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmAction, setConfirmAction] = useState<() => void>(() => {
    });
    const [viewMode, setViewMode] = useState<'record' | 'list'>('record');
    const [itemIsInKB, setItemIsInKB] = useState(false);

    const workspaceItemsLoaded = useSelector((state: RootState) => state.workspaceItems.items);
    const [workspaceItemSelected, setWorkspaceItemSelected] = useState<WorkspaceItem | undefined>(undefined);

    const [selectState, setSelectState] = useState(false);

    useEffect(() => {
        if ( records && records.length > 0 && selectedKeys.length === 0 ) {
            onRecordViewed && onRecordViewed(records[currentIndex]);
        } else {
            onRecordViewed && onRecordViewed(undefined);
        }
    }, [records, currentIndex]);

    useEffect(() => {

        // search workspace item for chunks based on the item reference
        const workspaceItem = workspaceItemsLoaded.find(item => item.uuid === itemRef?.uuid);
        if (workspaceItem && workspaceItem.chunks) {
            setItemIsInKB(workspaceItem.chunks > 0);
        }

        setWorkspaceItemSelected(workspaceItem);

        // if (selectedWorkspaceItem && selectedWorkspaceItem.chunks) {
        //     setItemIsInKB(selectedWorkspaceItem.chunks > 0);
        // }

    }, [itemRef]);

    const recordInViewMode = records[currentIndex];

    useEffect(() => {

        if (!recordInViewMode) return;

        setEditedRecord(recordInViewMode);

        // check if the selected key is in the list of keys
        if (selectedKeys && selectedKeys.length > 0) {
            if (selectedKeys.includes(recordInViewMode.key)) {
                setSelectState(true);
            } else {
                setSelectState(false);
            }
        } else {
            setSelectState(false);
        }

    }, [currentIndex, recordInViewMode, selectedKeys]);


    const handleViewModeChange = (event: React.MouseEvent<HTMLElement>, newMode: 'record' | 'list' | null) => {
        if (newMode !== null) {
            setViewMode(newMode);
            setIsEditing(false);
            setErrors({});
        }
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % records.length);
        setIsEditing(false);
        setErrors({});
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + records.length) % records.length);
        setIsEditing(false);
        setErrors({});
    };

    const handleEdit = () => {
        setIsEditing(true);
        setEditedRecord({...recordInViewMode});
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            await onSave(editedRecord);
            setIsEditing(false);
            setErrors({});
        } catch (error) {
            console.error("Error saving record:", error);
            setErrors({submit: "Failed to save record. Please try again."});
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        if (JSON.stringify(editedRecord) !== JSON.stringify(recordInViewMode)) {
            setShowConfirmDialog(true);
            setConfirmAction(() => () => {
                setIsEditing(false);
                setEditedRecord(recordInViewMode);
                setErrors({});
                setShowConfirmDialog(false);
            });
        } else {
            setIsEditing(false);
        }
    };

    const handleAddRemoveKB = () => {
        logger.info("Add to Knowledge Base");
        if (!workspaceItemSelected || !workspaceItemSelected.uuid) {
            logger.error("No workspace item selected");
            return;
        }

        if (!itemRef?.uuid) {
            logger.error("No item reference");
            return;
        }

        addRemoveSnippetToKb(itemRef.uuid, appSession.currentProject, appSession.accessToken).then(
            (response) => {
                if (response) {
                    logger.info("Add to Knowledge Base Response:", response);
                    const chunks = response.chunks || 0;
                    setItemIsInKB(chunks > 0);
                }
            }
        );
    }

    const handleClose = () => {
        if (isEditing && JSON.stringify(editedRecord) !== JSON.stringify(recordInViewMode)) {
            setShowConfirmDialog(true);
            setConfirmAction(() => () => {
                onClose();
                setShowConfirmDialog(false);
            });
        } else {
            onClose();
        }
    };

    const handleFieldChange = (key: string, value: any) => {
        setEditedRecord(prev => ({...prev, [key]: value}));
        setErrors(prev => ({...prev, [key]: ''}));
    };

    const validateField = (key: string, value: any): string => {
        if (value === '' || value === undefined) return "This field is required";
        if (typeof value === 'number' && isNaN(value)) return "Must be a valid number";
        return '';
    };

    const renderField = (key: string, value: any, isEditing: boolean, index: number) => {

        if (isEditing) {
            if (typeof value === 'boolean') {
                return (
                    <Select
                        fullWidth
                        value={value}
                        onChange={(e) => handleFieldChange(key, e.target.value === 'true')}
                        variant="outlined"
                        size="small"
                        error={!!errors[key]}
                    >
                        <MenuItem value="true">True</MenuItem>
                        <MenuItem value="false">False</MenuItem>
                    </Select>
                );
            } else if (typeof value === 'number') {
                return (
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="number"
                        value={value}
                        onChange={(e) => {
                            const newValue = e.target.value === '' ? '' : Number(e.target.value);
                            handleFieldChange(key, newValue);
                        }}
                        onBlur={(e) => setErrors(prev => ({...prev, [key]: validateField(key, e.target.value)}))}
                        error={!!errors[key]}
                        helperText={errors[key]}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">#</InputAdornment>,
                        }}
                    />
                );
            } else {
                return (
                    <TextField
                        fullWidth
                        variant="filled"
                        size='small'
                        value={value}
                        onChange={(e) => handleFieldChange(key, e.target.value)}
                        onBlur={(e) => setErrors(prev => ({...prev, [key]: validateField(key, e.target.value)}))}
                        error={!!errors[key]}
                        helperText={errors[key]}
                    />
                );
            }
        } else {
            return <Typography variant={index === 0 ? "h5" : "body1"}>{value.toString()}</Typography>;
        }
    };

    const handleRecordSelect = () => {
        handleRequest && handleRequest('select', recordInViewMode.key, selectedFile ? selectedFile.columns : []);
    }

    return (
        <>
            <DialogContent dividers sx={{padding: '0px !important', maxHeight: `calc(100% - ${120}px)` }}>

                {viewMode === 'record' ? (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                // height: `calc(100% - ${120}px)`,
                                flex: 1,
                                overflowY: 'auto',
                                flexDirection: 'column',
                                border: selectState ? '1px solid red' : 'none',
                                borderRadius: '4px',
                                padding: selectState ? '5px' : '6px',
                                position: 'relative'
                            }}
                            onClick={handleRecordSelect}
                        >
                            {(isEditing ? editedRecord : recordInViewMode) &&
                                Object.entries(isEditing ? editedRecord : recordInViewMode)
                                    .filter(([key]) => key !== 'key' && key !== 'category')
                                    .map(([key, value], index) => (
                                        <Box key={key} sx={{mb: 2}}>
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                {key}
                                            </Typography>
                                            {renderField(key, value, isEditing, index)}
                                            <Divider sx={{mt: 1}}/>
                                        </Box>
                                    ))
                            }
                        </Box>
                    </>
                ) : (
                    <Box sx={{
                        display: 'flex',
                        flex: '1 1 0',
                        overflowY: "auto",
                        margin: '10px',
                        position: 'relative',
                        flexDirection: 'column',
                    }}>
                        {columns.length > 0 && (
                            <AppTable
                                data={records}
                                recordsPerPage={8}
                                title=""
                                filter=""
                                filterValues={[]}
                                columns={columns}
                                selectColumnsToShow={selectedFile ? selectedFile.columns : []}
                                menu={menuItem}
                                menuTable={menuTable}
                                selectMode={"select"}
                                onRequest={handleRequest ? handleRequest : () => {
                                }}
                                mode="manage"
                                selected={selectedKeys ? selectedKeys : []}
                                hideHeaders={false}
                                allowDelete={true}
                                allowEdit={true}
                                allowView={true}
                                allowAdd={false}
                            />
                        )}
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{justifyContent: 'space-between', alignItems: 'center', px: 2, py: 1, display: 'flex', height: '80px'}}>
                <>
                    {/* Switching between record and list mode is only available in modal mode */}
                    {isModalMode && (
                        <Box>
                            <ToggleButtonGroup
                                value={viewMode}
                                exclusive
                                onChange={handleViewModeChange}
                                aria-label="view mode"
                            >
                                <ToggleButton size={"small"} value="record" aria-label="record view">
                                    <RecordIcon/>
                                </ToggleButton>
                                <ToggleButton size={"small"} value="list" aria-label="list view">
                                    <ListIcon/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    )}

                    {viewMode === 'record' && (
                            <div style={{flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                                <Tooltip title="Previous Record">
                                    <span>
                                      <IconButton onClick={handlePrevious} color="primary" disabled={isEditing}>
                                        <PreviousIcon/>
                                      </IconButton>
                                    </span>
                                </Tooltip>
                                <Typography variant="subtitle1">
                                    {currentIndex + 1} / {records.length}
                                </Typography>
                                <Tooltip title="Next Record">
                                    <span>
                                      <IconButton onClick={handleNext} color="primary" disabled={isEditing}>
                                        <NextIcon/>
                                      </IconButton>
                                    </span>
                                </Tooltip>
                            </div>
                    )}

                    {viewMode === 'record' ? (
                            <Box>
                                {isEditing ? (
                                    <>
                                        <Tooltip title="Cancel Editing">
                                            <IconButton onClick={handleCancel} color="default">
                                                <CancelIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Save Changes">
                                            <IconButton onClick={handleSave} color="primary"
                                                        disabled={isSaving || Object.values(errors).some(error => error !== '')}>
                                                {isSaving ? <CircularProgress size={24}/> : <SaveIcon/>}
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                ) : (
                                    <Tooltip title="Edit Record">
                                        <IconButton onClick={handleEdit} color="primary">
                                            <EditIcon/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                        ) :
                        (
                            <>
                                <Tooltip title={itemIsInKB ? "Remove From Knowledge" : "Add To Knowledge"}>
                                    <IconButton onClick={handleAddRemoveKB} size="small">
                                        <ArticleOutlinedIcon
                                            sx={{color: itemIsInKB ? colorSchema.success.dark : 'inherit'}}/>
                                    </IconButton>
                                </Tooltip>
                            </>)
                    }
                </>
            </DialogActions>

            <Dialog
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have unsaved changes. Are you sure you want to discard them?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowConfirmDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => confirmAction()} color="primary" autoFocus>
                        Discard Changes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
        ;
};

export default RecordViewer;